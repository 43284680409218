import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import actions from "@/actions";
import { FilterConfigState } from "@/reducers/filterConfig";
import { RootState } from "@/reducers/types";

// 条件クリアしたときに保存した検索条件の選択肢もクリアする
// また、検索条件を保存したときも選択済みの検索条件をクリアする
export const useFilterConfigSelectEvent = (clearValue: () => void) => {
  const { clearSelectedFilterConfig } = useSelector<RootState, FilterConfigState>((state) => state.filterConfig);
  const dispatch = useDispatch();

  useEffect(() => {
    // NOTE: 通常は親コンポーネントで制御するが共通的な処理なので仕方ない
    // 選択をクリアしたい箇所で以下のようにする
    // dispatch(actions.filterConfig.clearSelectedFilterConfig());
    if (clearSelectedFilterConfig) {
      clearValue();
      setTimeout(() => {
        dispatch(actions.filterConfig.resetClearSelectedFilterConfig());
      }, 1000);
    }
  }, [clearSelectedFilterConfig]);
};
