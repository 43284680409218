import { CreateFilterConfigParams } from "@/actions/types/filterConfig";
import * as constants from "@/constants";
import environments from "@/environments";
import { ScreenNameType } from "@/models/screenConfig";

export const fetchFilterConfigs = (screenName: ScreenNameType, callback?) => {
  const endpoint = `${environments.API_BASE_URI}/filter_configs`;

  const query = new URLSearchParams();
  query.set("screen_name", screenName);

  return {
    type: constants.APP_CALL_API,
    endpoint: `${endpoint}?${query.toString()}`,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.FILTER_CONFIG_BEGIN_FETCH,
        };
      },
      success: (response) => {
        callback?.(response?.filter_configs ?? null);

        return {
          type: constants.FILTER_CONFIG_END_FETCH,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const createFilterConfig = (params: CreateFilterConfigParams, callback?) => {
  const endpoint = `${environments.API_BASE_URI}/filter_configs/create`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(params),
    callbacks: {
      begin: () => {
        return {
          type: constants.FILTER_CONFIG_BEGIN_CREATE,
        };
      },
      success: (response) => {
        callback?.(response);

        return {
          type: constants.FILTER_CONFIG_END_CREATE,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const deleteFilterConfig = (id: number, callback?) => {
  const endpoint = `${environments.API_BASE_URI}/filter_configs/delete/${id}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    callbacks: {
      begin: () => {
        return {
          type: constants.FILTER_CONFIG_BEGIN_DELETE,
        };
      },
      success: (response) => {
        callback?.(response);

        return {
          type: constants.FILTER_CONFIG_END_DELETE,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const clearSelectedFilterConfig = () => {
  return {
    type: constants.FILTER_CONFIG_CLEAR_SELECTED,
  };
};

export const resetClearSelectedFilterConfig = () => {
  return {
    type: constants.FILTER_CONFIG_RESET_CLEAR_SELECTED,
  };
};
