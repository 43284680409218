import * as constants from "../constants/index";
import storageManager from "../lib/storageManager";

import { hideConstructionSelector } from "./app";

export const changeCategory = (items) => ({
  type: constants.COMMENT_CHANGE_CATEGORY,
  payload: items,
});

export const changeComment = (comment) => ({
  type: constants.COMMENT_CHANGE_COMMENT,
  payload: comment,
});

export const changeDateFrom = (insertStartDate) => ({
  type: constants.COMMENT_CHANGE_DATE_FROM,
  payload: insertStartDate,
});

export const changeDateTo = (insertEndDate) => ({
  type: constants.COMMENT_CHANGE_DATE_TO,
  payload: insertEndDate,
});

export const clearSearch = (target) => ({
  type: constants.COMMENT_CLEAR_SEARCH,
  payload: target,
});

export const revertLocalCondition = () => ({
  type: constants.ASSET_REVERT_CONDITION,
});

export const search = (params, callback) => {
  storageManager.setConstructionItem("commentSearchParams", JSON.stringify(params));

  return;
};
