import * as constants from "../constants/index";

export type FilterConfigState = {
  clearSelectedFilterConfig: boolean;
};

const filterConfig = (state: FilterConfigState = { clearSelectedFilterConfig: false }, action) => {
  switch (action.type) {
    case constants.FILTER_CONFIG_CLEAR_SELECTED: {
      return { ...state, clearSelectedFilterConfig: true };
    }
    case constants.FILTER_CONFIG_RESET_CLEAR_SELECTED: {
      return { ...state, clearSelectedFilterConfig: false };
    }
    default:
      return state;
  }
};

export default filterConfig;
