import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import actions from "@/actions";
import { FilterConfig } from "@/models/filterConfig";
import { ScreenNameType } from "@/models/screenConfig";

type Props = {
  screenName: ScreenNameType;
};

export const useFilterConfig = ({ screenName }: Props) => {
  const dispatch = useDispatch();

  const [values, setValues] = useState<FilterConfig[]>();

  useEffect(() => {
    fetch();
  }, []);

  const fetch = () => {
    dispatch(
      actions.filterConfig.fetchFilterConfigs(screenName, (response: FilterConfig[] | null) => {
        setValues(response);
      })
    );
  };

  const create = (params: { label: string; config: object }) => {
    dispatch(
      actions.filterConfig.createFilterConfig(
        {
          screen_name: screenName,
          label: params.label,
          config: JSON.stringify(params.config),
        },
        (response: FilterConfig) => setValues([response].concat(values))
      )
    );
  };

  const remove = (o: FilterConfig) => {
    dispatch(
      actions.filterConfig.deleteFilterConfig(o.filter_config_id, () =>
        setValues(values.filter((v) => v.filter_config_id !== o.filter_config_id))
      )
    );
  };

  return {
    values,
    fetch,
    create,
    remove,
  };
};
