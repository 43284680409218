import moment from "moment";
import PropTypes from "prop-types";
import React, { Component, createRef } from "react";
import { withTranslation } from "react-i18next";

import InazumaPanelContainer from "../../containers/summary/InazumaPanelContainer";
import SchedulePanelContainer from "../../containers/summary/SchedulePanelContainer";
import SchedulePanelCosmoChibaContainer from "../../containers/summary/SchedulePanelCosmoChibaContainer";
import SchedulePanelMitsuiIwakuniContainer from "../../containers/summary/SchedulePanelMitsuiIwakuniContainer";
import SchedulePanelOpcOsakaContainer from "../../containers/summary/SchedulePanelOpcOsakaContainer";
import SearchPanel from "../../containers/summary/SearchPanelContainer";
import { addHeightResizeListener, removeHeightResizeListener, getTableBodyHeight } from "../../lib/common";
import * as util from "../../lib/common";
import restoreState from "../../lib/restoreState";
import storageManager from "../../lib/storageManager";
import ConfirmDialog from "../ConfirmDialog";
import Modal from "../Modal";

import LeftGridBody from "./LeftGridBody";
import LeftGridHeader from "./LeftGridHeader";
import PulldownExport from "./PulldownExport";
import RightGridBody from "./RightGridBody";
import RightGridHeader from "./RightGridHeader";

import { SummarySearchParams } from "@/models/summary";

let autoload = false; // IE double request prevention flag

class Summary extends Component {
  searchBox;

  constructor(props) {
    super(props);
    this.searchBox = createRef();
    this.state = {
      showSearch: true,
      showTable: false,
      downloadUrl: "",
      downloadName: "",
      downloading: false,
      limit: 9999,
      start: 1,
      end: false,
      isEmpty: false,
      tableBodyMaxHeight: window.innerHeight - 420,
      visibleInazumaPanel: false,
      visibleGraphConfirm: false,
      visibleSchedulePanel: false,
      visibleSchedulePanelOpcOsaka: false,
      visibleSchedulePanelMitsuiIwakuni: false,
      visibleSchedulePanelCosmoChiba: false,
      visibleDelayConfirm: false,
      visibleGraphResult: false,
      visibleDelayResult: false,
      allSelectedCells: [],
      extensionType: 0,
      isLeftHeight: false,
      requestParam: {},
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.showInazumaPanel = this.showInazumaPanel.bind(this);
    this.hideInazumaPanel = this.hideInazumaPanel.bind(this);
    this.showSchedulePanel = this.showSchedulePanel.bind(this);
    this.hideSchedulePanel = this.hideSchedulePanel.bind(this);
    this.hideSchedulePanelOpcOsaka = this.hideSchedulePanelOpcOsaka.bind(this);
    this.hideSchedulePanelMitsuiIwakuni = this.hideSchedulePanelMitsuiIwakuni.bind(this);
    this.hideSchedulePanelCosmoChiba = this.hideSchedulePanelCosmoChiba.bind(this);
    this.syncScroll = this.syncScroll.bind(this);
    this.infiniteSearch = this.infiniteSearch.bind(this);
    this.handleSearchBoxHeightChange = this.handleSearchBoxHeightChange.bind(this);
    this.isScrollable = this.isScrollable.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.fillList = this.fillList.bind(this);
    this.download = this.download.bind(this);
    this.showGraphConfirm = this.showGraphConfirm.bind(this);
    this.hideGraphConfirm = this.hideGraphConfirm.bind(this);
    this.handleConfirmGraph = this.handleConfirmGraph.bind(this);
    this.showGraphResult = this.showGraphResult.bind(this);
    this.hideGraphResult = this.hideGraphResult.bind(this);
    this.showDelayConfirm = this.showDelayConfirm.bind(this);
    this.hideDelayConfirm = this.hideDelayConfirm.bind(this);
    this.handleConfirmDelay = this.handleConfirmDelay.bind(this);
    this.showDelayResult = this.showDelayResult.bind(this);
    this.hideDelayResult = this.hideDelayResult.bind(this);
    this.copyInazumaAllTargets = this.copyInazumaAllTargets.bind(this);
    this.copyGraphAllTargets = this.copyGraphAllTargets.bind(this);
    this.copyScheduleAllTargets = this.copyScheduleAllTargets.bind(this);
    this.copyDelayAllTargets = this.copyDelayAllTargets.bind(this);
    this.judgeScheduleDate = this.judgeScheduleDate.bind(this);
    this.handleClickSchedule = this.handleClickSchedule.bind(this);
  }

  toggleSearch() {
    const callback = () => {
      this.handleSearchBoxHeightChange();
      addHeightResizeListener(this.searchBox.current, this.handleSearchBoxHeightChange);
      this.fillList();
    };
    this.setState({ showSearch: !this.state.showSearch }, () => setTimeout(callback, 100));
  }

  componentDidMount() {
    this.resizeTimer = 0;
    window.addEventListener("resize", this.handleResize);

    const stateUrl = restoreState();
    const { validated } = this.props;
    if (
      validated &&
      (storageManager.getConstructionItem("summarySearchParams") !== null || (stateUrl && stateUrl.hasQuery))
    ) {
      this.handleSearch();
      this.setState({ showTable: true });
    }

    this.handleSearchBoxHeightChange();
    addHeightResizeListener(this.searchBox.current, this.handleSearchBoxHeightChange);
  }

  componentDidUpdate(prevProps) {
    const { validated } = this.props;
    if (!validated) {
      return;
    }
    const stateUrl = restoreState();
    const notFirst =
      storageManager.getConstructionItem("summarySearchParams") !== null || (stateUrl && stateUrl.hasQuery);
    if (!prevProps.validated && validated && notFirst) {
      this.handleSearch();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    removeHeightResizeListener(this.searchBox.current);
    removeHeightResizeListener(this.leftBodyContainer);

    this.props.revertLocalCondition();
  }

  handleSearchBoxHeightChange() {
    const maxHeight = getTableBodyHeight("summary", this.searchBox.current, this.rightHeaderContainer);
    this.setState({ tableBodyMaxHeight: maxHeight });
  }

  handleResize() {
    if (this.resizeTimer > 0) {
      clearTimeout(this.resizeTimer);
    }

    const callback = () => {
      this.handleSearchBoxHeightChange();
      this.fillList();
    };

    this.resizeTimer = setTimeout(callback, 200);
  }

  isScrollable() {
    const container = this.leftBodyContainer;

    if (!container) {
      return false;
    }

    const diff = container.clientHeight - container.scrollHeight;

    return diff !== 0;
  }

  fillList() {
    const { showTable, end } = this.state;

    if (!showTable || end) {
      return;
    }

    if (!this.isScrollable()) {
      this.infiniteSearch();
    }
  }

  handleSearch(option?: { params?: SummarySearchParams }) {
    this.setState({ isEmpty: true });

    const {
      areaIds,
      deviceIds,
      categoryIds,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      search,
      setConditions,
    } = this.props;

    const params = {
      areaIds,
      deviceIds,
      categoryIds,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      limit: this.state.limit,
      start: 1,
      ...option?.params,
    };

    option?.params && setConditions(option.params);

    search(params, (data) => {
      this.setState(
        {
          start: data.summaries.length + 1,
          end: data.summaries.length < this.state.limit,
          isEmpty: data.summaries.length === 0,
          requestParam: params,
        },
        () => {
          this.showTable(this.fillList);
          this.handleSelectAllTargets(data.summaries);
          this.handleSearchBoxHeightChange();
        }
      );
    });
  }

  infiniteSearch() {
    if (autoload) {
      return;
    }

    autoload = true;

    const {
      areaIds,
      deviceIds,
      categoryIds,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      search,
    } = this.props;

    const params = {
      areaIds,
      deviceIds,
      categoryIds,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      limit: this.state.limit,
      start: this.state.start,
    };

    search(params, (data) => {
      autoload = false;

      this.setState(
        {
          start: this.state.start + data.summaries.length,
          end: data.summaries.length < this.state.limit,
        },
        this.fillList
      );
    });
  }

  handleReset() {
    this.props.resetSearch();
    this.setState({
      start: 1,
      end: false,
      requestParam: {},
    });

    // Reset scroll position to top
    if (this.rightBodyContainer) {
      this.rightBodyContainer.scrollTop = 0;
    }

    this.props.search(
      {
        areaIds: [],
        deviceIds: [],
        categoryIds: [],
        facilityManagementIds: [],
        constructionManagementIds: [],
        primaryChargeIds: [],
        start: 1,
        limit: this.state.limit,
      },
      (data) => {
        this.setState({
          start: this.state.start + data.summaries.length,
          end: data.summaries.length < this.state.limit,
          isEmpty: data.summaries.length === 0,
        });

        this.showTable(this.fillList);
      }
    );
  }

  judgeScheduleDate() {
    const config = this.props.extensions.extensions.filter((item) => item.extension_type === this.state.extensionType);
    const timeZone = JSON.parse(config[0].config).selectable_time_zone;
    let active1 = false;
    let active2 = false;
    let active3 = false;
    let initCheck = -1;

    timeZone.date1.forEach((date) => {
      const startTime = moment(moment().format("YYYY-MM-DD") + " " + ("0" + date.start_time).slice(-5) + ":00");
      const endTime = moment(moment().format("YYYY-MM-DD") + " " + ("0" + date.end_time).slice(-5) + ":00");
      if (startTime <= moment() && moment() <= endTime) {
        active1 = true;
        if (initCheck === -1) initCheck = 0;
      }
    });

    timeZone.date2.forEach((date) => {
      const startTime = moment(moment().format("YYYY-MM-DD") + " " + ("0" + date.start_time).slice(-5) + ":00");
      const endTime = moment(moment().format("YYYY-MM-DD") + " " + ("0" + date.end_time).slice(-5) + ":00");
      if (startTime <= moment() && moment() <= endTime) {
        active2 = true;
        if (initCheck === -1) initCheck = 1;
      }
    });

    timeZone.date3.forEach((date) => {
      const startTime = moment(moment().format("YYYY-MM-DD") + " " + ("0" + date.start_time).slice(-5) + ":00");
      const endTime = moment(moment().format("YYYY-MM-DD") + " " + ("0" + date.end_time).slice(-5) + ":00");
      if (startTime <= moment() && moment() <= endTime) {
        active3 = true;
        if (initCheck === -1) initCheck = 2;
      }
    });

    const scheduleInfo = {
      scheduleDateLists: [
        { id: 0, value: moment().add(1, "days").format("YYYY-MM-DD"), active: active1 },
        { id: 1, value: moment().add(2, "days").format("YYYY-MM-DD"), active: active2 },
        { id: 2, value: moment().add(3, "days").format("YYYY-MM-DD"), active: active3 },
      ],
      initCheck,
    };

    return scheduleInfo;
  }

  showInazumaPanel() {
    this.setState({ visibleInazumaPanel: true });
  }

  hideInazumaPanel() {
    this.setState({ visibleInazumaPanel: false });
    this.props.endSelectInazuma();
  }

  showSchedulePanel(type) {
    if (type === 10) {
      this.setState({ visibleSchedulePanelOpcOsaka: true, scheduleInfo: this.judgeScheduleDate() });
    } else if (type === 11) {
      this.setState({ visibleSchedulePanelMitsuiIwakuni: true, scheduleInfo: this.judgeScheduleDate() });
    } else if (type === 16) {
      this.setState({ visibleSchedulePanelCosmoChiba: true });
    } else {
      this.setState({ visibleSchedulePanel: true, scheduleInfo: this.judgeScheduleDate() });
    }
  }

  hideSchedulePanel() {
    this.setState({ visibleSchedulePanel: false });
    this.props.endSelectSchedule();
  }

  hideSchedulePanelOpcOsaka() {
    this.setState({ visibleSchedulePanelOpcOsaka: false });
    this.props.endSelectSchedule();
  }

  hideSchedulePanelMitsuiIwakuni() {
    this.setState({ visibleSchedulePanelMitsuiIwakuni: false });
    this.props.endSelectSchedule();
  }

  hideSchedulePanelCosmoChiba() {
    this.setState({ visibleSchedulePanelCosmoChiba: false });
    this.props.endSelectSchedule();
  }

  showGraphConfirm() {
    this.setState({ visibleGraphConfirm: true });
  }

  hideGraphConfirm() {
    this.setState({ visibleGraphConfirm: false });
  }

  showDelayConfirm() {
    this.setState({ visibleDelayConfirm: true });
  }

  hideDelayConfirm() {
    this.setState({ visibleDelayConfirm: false });
  }

  showGraphResult() {
    this.setState({ visibleGraphResult: true });
  }

  hideGraphResult() {
    this.setState({ visibleGraphResult: false });
  }

  showDelayResult() {
    this.setState({ visibleDelayResult: true });
  }

  hideDelayResult() {
    this.setState({ visibleDelayResult: false });
  }

  showTable(callback) {
    this.setState({ showTable: true }, () => {
      if (callback !== undefined) {
        setTimeout(callback, 100);
      }
    });
  }

  syncScroll(e) {
    this.rightHeaderContainer.scrollLeft = e.target.scrollLeft;
    this.leftBodyContainer.scrollTop = e.target.scrollTop;

    const container = this.rightBodyContainer;
    const containerHeight = container.clientHeight;
    const contentHeight = container.scrollHeight;
    const scrollTop = container.scrollTop;
    const diff = contentHeight - (containerHeight + scrollTop);

    if (diff <= 10 && !this.state.end) {
      this.infiniteSearch();
    }
  }

  syncWheel(e) {
    this.rightBodyContainer.scrollTop += e.deltaY;
    this.leftBodyContainer.scrollTop += e.deltaY;
  }

  download() {
    this.setState({ downloading: true });

    const { areaIds, deviceIds, categoryIds, facilityManagementIds, constructionManagementIds, primaryChargeIds } =
      this.state.requestParam;
    const params = {
      areaIds,
      deviceIds,
      categoryIds,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
    };

    const fallback = () => this.setState({ downloading: false });

    this.props.download(
      params,
      (blob, fileName) => {
        const fname = !fileName ? "summary.xls" : fileName;

        this.setState({ downloadUrl: URL.createObjectURL(blob), downloadName: fname }, () => {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fname);
          } else {
            const evt = util.createClickEvent();
            this.btnDownload.dispatchEvent(evt);
          }
          this.setState({ downloading: false });
        });
      },
      fallback
    );
  }

  handleConfirmGraph() {
    const { createGraph, endSelectGraph } = this.props;

    const callback = () => {
      this.showGraphResult();
      this.hideGraphConfirm();
      endSelectGraph();
    };

    createGraph(callback);
  }

  handleConfirmDelay() {
    const { createDelayList, endSelectDelay } = this.props;

    const callback = () => {
      this.showDelayResult();
      this.hideDelayConfirm();
      endSelectDelay();
    };

    createDelayList(callback);
  }

  handleSelectAllTargets(summaries) {
    let cells = [];

    summaries.forEach((row) => {
      row.category_summaries.forEach((data) => {
        if (data.item_num) {
          const cell = { row: row, data: data };
          cells = [...cells, cell];
        }
      });
    });

    if (cells.length > 0) {
      this.setState({ allSelectedCells: cells });
    } else {
      this.setState({ isAllCell: false });
    }
  }

  copyInazumaAllTargets() {
    this.props.clearInazumaCells();
    this.state.allSelectedCells.forEach((cell) => {
      this.props.copyInazumaCell(cell.row, cell.data);
    });
  }

  copyGraphAllTargets() {
    this.props.clearGraphCells();
    this.state.allSelectedCells.forEach((cell) => {
      this.props.copyGraphCell(cell.row, cell.data);
    });
  }

  copyScheduleAllTargets() {
    this.props.clearScheduleCells();
    this.state.allSelectedCells.forEach((cell) => {
      this.props.copyScheduleCell(cell.row, cell.data);
    });
  }

  copyDelayAllTargets() {
    this.props.clearDelayCells();
    this.state.allSelectedCells.forEach((cell) => {
      this.props.copyDelayCell(cell.row, cell.data);
    });
  }

  handleClickSchedule(type) {
    this.props.beginSelectSchedule();
    this.setState({ extensionType: type });
  }

  refRightHeader(el) {
    this.rightHeaderContainer = el;

    const ele = document.getElementById("left-header");
    const rightheaderHeight = this.rightHeaderContainer ? this.rightHeaderContainer.offsetHeight - 1 : 27;
    ele.style.height = rightheaderHeight + "px";

    if (this.rightHeaderContainer && !this.state.isLeftHeight) {
      this.handleSearchBoxHeightChange();
      addHeightResizeListener(this.leftBodyContainer, this.handleSearchBoxHeightChange);
      this.setState({ isLeftHeight: true });
    }
  }

  render() {
    const titleClassName = `toggle icon-keyboard_arrow_up ${!this.state.showSearch ? "closed" : ""}`;
    const {
      fetching,
      areaIds,
      deviceIds,
      categoryIds,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      t,
      isInazumaSelecting,
      selectedItemCount,
      inazumaSelectedCells,
      isGraphSelecting,
      graphSelectedCells,
      selectedItemCountGraph,
      isScheduleSelecting,
      scheduleSelectedCells,
      selectedItemCountSchedule,
      isDelaySelecting,
      delaySelectedCells,
      selectedItemCountDelay,
      extensions,
    } = this.props;

    return (
      <div className="contents">
        <div id="inazuma" className={`${isInazumaSelecting ? "inazuma" : "inazuma off"}`}>
          <p>{t("picking_objectives_for_inazuma_lines")}</p>
          <button data-test-id="button-inazuma-close" className="panel-close" onClick={this.props.endSelectInazuma}>
            <img src="./img/close.png" alt="XLS" className="" />
          </button>
          <ul className="disp-area">
            <li>
              {t("selected_category")}：<span>{inazumaSelectedCells.length}</span>
            </li>
            <li>
              {t("selected_items_sum")}：<span>{selectedItemCount}</span>
            </li>
          </ul>
          <div className="button-area">
            <button
              disabled={this.state.allSelectedCells.length === 0}
              data-test-id="button-inazuma-allselect"
              className="btn btn-blue all-select"
              onClick={this.copyInazumaAllTargets}
            >
              {t("allselect")}
            </button>
            <button
              disabled={inazumaSelectedCells.length === 0}
              data-test-id="button-inazuma-allclear"
              className="btn btn-gray all-clear"
              onClick={this.props.clearInazumaCells}
            >
              {t("allclear")}
            </button>
            <button
              disabled={inazumaSelectedCells.length === 0}
              data-test-id="button-inazuma-decision"
              className="btn btn-blue decision"
              onClick={this.showInazumaPanel}
            >
              {t("decision")}
            </button>
          </div>
        </div>
        <div id="graph" className={`${isGraphSelecting ? "inazuma" : "inazuma off"}`}>
          <p>{t("picking_objectives_for_graph_lines")}</p>
          <button data-test-id="button-graph-close" className="panel-close" onClick={this.props.endSelectGraph}>
            <img src="./img/close.png" alt="XLS" className="" />
          </button>
          <ul className="disp-area">
            <li>
              {t("selected_category")}：<span>{graphSelectedCells.length}</span>
            </li>
            <li>
              {t("selected_items_sum")}：<span>{selectedItemCountGraph}</span>
            </li>
          </ul>
          <div className="button-area">
            <button
              data-test-id="button-graph-allselect"
              className="btn btn-blue all-select"
              onClick={this.copyGraphAllTargets}
            >
              {t("allselect")}
            </button>
            <button
              disabled={graphSelectedCells.length === 0}
              data-test-id="button-graph-allclear"
              className="btn btn-gray all-clear"
              onClick={this.props.clearGraphCells}
            >
              {t("allclear")}
            </button>
            <button
              disabled={graphSelectedCells.length === 0}
              data-test-id="button-graph-decision"
              className="btn btn-blue decision"
              onClick={this.showGraphConfirm}
            >
              {t("decision")}
            </button>
          </div>
        </div>
        <div id="schedule" className={`${isScheduleSelecting ? "inazuma" : "inazuma off"}`}>
          <p>{t("picking_objectives_for_schedule_lines")}</p>
          <button data-test-id="button-inazuma-close" className="panel-close" onClick={this.props.endSelectSchedule}>
            <img src="./img/close.png" alt="XLS" className="" />
          </button>
          <ul className="disp-area">
            <li>
              {t("selected_category")}：<span>{scheduleSelectedCells.length}</span>
            </li>
            <li>
              {t("selected_items_sum")}：<span>{selectedItemCountSchedule}</span>
            </li>
          </ul>
          <div className="button-area">
            <button
              disabled={this.state.allSelectedCells.length === 0}
              data-test-id="button-inazuma-allselect"
              className="btn btn-blue all-select"
              onClick={this.copyScheduleAllTargets}
            >
              {t("allselect")}
            </button>
            <button
              disabled={scheduleSelectedCells.length === 0}
              data-test-id="button-inazuma-allclear"
              className="btn btn-gray all-clear"
              onClick={this.props.clearScheduleCells}
            >
              {t("allclear")}
            </button>
            <button
              disabled={scheduleSelectedCells.length === 0}
              data-test-id="button-inazuma-decision"
              className="btn btn-blue decision"
              onClick={() => this.showSchedulePanel(this.state.extensionType)}
            >
              {t("decision")}
            </button>
          </div>
        </div>
        <div id="delay" className={`${isDelaySelecting ? "inazuma" : "inazuma off"}`}>
          <p>{t("picking_objectives_for_delay_lines")}</p>
          <button data-test-id="button-delay-close" className="panel-close" onClick={this.props.endSelectDelay}>
            <img src="./img/close.png" alt="XLS" className="" />
          </button>
          <ul className="disp-area">
            <li>
              {t("selected_category")}：<span>{delaySelectedCells.length}</span>
            </li>
            <li>
              {t("selected_items_sum")}：<span>{selectedItemCountDelay}</span>
            </li>
          </ul>
          <div className="button-area">
            <button
              data-test-id="button-delay-allselect"
              className="btn btn-blue all-select"
              onClick={this.copyDelayAllTargets}
            >
              {t("allselect")}
            </button>
            <button
              disabled={delaySelectedCells.length === 0}
              data-test-id="button-delay-allclear"
              className="btn btn-gray all-clear"
              onClick={this.props.clearDelayCells}
            >
              {t("allclear")}
            </button>
            <button
              disabled={delaySelectedCells.length === 0}
              data-test-id="button-delay-decision"
              className="btn btn-blue decision"
              onClick={this.showDelayConfirm}
            >
              {t("decision")}
            </button>
          </div>
        </div>
        <div className="inner">
          <h1 className="page-ttl">
            {t("construction_summary")}
            <span
              data-test-id="button-summary-toggle-search"
              className={titleClassName}
              onClick={() => this.toggleSearch()}
            ></span>
          </h1>
          {this.state.showSearch && (
            <SearchPanel ref={this.searchBox} handleReset={this.handleReset} handleSearch={this.handleSearch} />
          )}
          <div className={fetching ? "loading loading--list" : ""}>
            {this.state.isEmpty
              ? this.state.showTable && !fetching && <p className="empty-message">{t("no_data")}</p>
              : this.state.showTable && (
                  <React.Fragment>
                    <div className="tbl-top-area clearfix relative">
                      <div className="tbl-top-left">
                        <PulldownExport
                          beginSelectInazuma={this.props.beginSelectInazuma}
                          beginSelectGraph={this.props.beginSelectGraph}
                          beginSelectDelay={this.props.beginSelectDelay}
                          download={this.download}
                          extensions={extensions.extensions}
                          handleClickSchedule={this.handleClickSchedule}
                        />
                      </div>
                      <div className={`${this.state.downloading ? "loading-small loading-small-download" : ""}`} />
                    </div>
                    <div className="tbl-area">
                      <div
                        className={`grid-container grid-container-summary ${!this.state.showSearch ? "is-high" : ""}`}
                      >
                        <div className="grid-left-header" ref={(el) => (this.leftHeaderContainer = el)}>
                          <LeftGridHeader />
                        </div>
                        <div
                          className="grid-left-body"
                          ref={(el) => (this.leftBodyContainer = el)}
                          onWheel={(e) => this.syncWheel(e)}
                          style={{ maxHeight: this.state.tableBodyMaxHeight }}
                        >
                          <LeftGridBody rows={this.props.summaries} />
                        </div>
                        <div className="grid-right-header" ref={(el) => this.refRightHeader(el)}>
                          <RightGridHeader columns={this.props.categories} />
                        </div>
                        <div
                          className="grid-right-body"
                          onScroll={this.syncScroll}
                          ref={(node) => (this.rightBodyContainer = node)}
                          style={{ maxHeight: this.state.tableBodyMaxHeight }}
                        >
                          <RightGridBody ref={(node) => (this.rightBodyContents = node)} rows={this.props.summaries} />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
          </div>
          <a
            className="d-n"
            download={this.state.downloadName}
            href={this.state.downloadUrl}
            ref={(node) => (this.btnDownload = node)}
          >
            download
          </a>
        </div>
        {this.state.visibleInazumaPanel && (
          <InazumaPanelContainer
            closeHandler={this.hideInazumaPanel}
            backHandler={this.props.endSelectInazuma}
            decisionHandler={this.handleSave}
            selectedCategoryCount={inazumaSelectedCells.length}
            targetItemCount={this.props.selectedItemCount}
          />
        )}
        {this.state.visibleSchedulePanel && (
          <SchedulePanelContainer
            closeHandler={this.hideSchedulePanel}
            backHandler={this.props.endSelectSchedule}
            decisionHandler={this.handleSave}
            selectedCategoryCount={scheduleSelectedCells.length}
            targetItemCount={this.props.selectedItemCountSchedule}
            scheduleInfo={this.state.scheduleInfo}
            extensionType={this.state.extensionType}
          />
        )}
        {this.state.visibleSchedulePanelOpcOsaka && (
          <SchedulePanelOpcOsakaContainer
            closeHandler={this.hideSchedulePanelOpcOsaka}
            backHandler={this.props.endSelectSchedule}
            decisionHandler={this.handleSave}
            selectedCategoryCount={scheduleSelectedCells.length}
            targetItemCount={this.props.selectedItemCountSchedule}
            scheduleInfo={this.state.scheduleInfo}
            extensionType={this.state.extensionType}
          />
        )}
        {this.state.visibleSchedulePanelMitsuiIwakuni && (
          <SchedulePanelMitsuiIwakuniContainer
            closeHandler={this.hideSchedulePanelMitsuiIwakuni}
            backHandler={this.props.endSelectSchedule}
            decisionHandler={this.handleSave}
            selectedCategoryCount={scheduleSelectedCells.length}
            targetItemCount={this.props.selectedItemCountSchedule}
            scheduleInfo={this.state.scheduleInfo}
            extensionType={this.state.extensionType}
          />
        )}
        {this.state.visibleSchedulePanelCosmoChiba && (
          <SchedulePanelCosmoChibaContainer
            closeHandler={this.hideSchedulePanelCosmoChiba}
            backHandler={this.props.endSelectSchedule}
            decisionHandler={this.handleSave}
            selectedCategoryCount={scheduleSelectedCells.length}
            targetItemCount={this.props.selectedItemCountSchedule}
            extensionType={this.state.extensionType}
          />
        )}
        {this.state.visibleGraphConfirm && (
          <ConfirmDialog
            title="confirmation"
            messages={["confirm_create_graph"]}
            okClickHandler={this.handleConfirmGraph}
            cancelClickHandler={this.hideGraphConfirm}
          />
        )}
        {this.state.visibleDelayConfirm && (
          <ConfirmDialog
            title="confirmation"
            messages={["confirm_create_delay"]}
            okClickHandler={this.handleConfirmDelay}
            cancelClickHandler={this.hideDelayConfirm}
          />
        )}
        {this.state.visibleGraphResult && (
          <Modal title={t("output_graph")} closeHandler={this.hideGraphResult}>
            <div className="modal-body modal-inazuma-confirm">
              <p>
                {t("output_graph_1")}
                <br />
                {t("output_graph_2")}
              </p>
              <p>
                <br />
                {t("output_inazuma_3")}
                <br />
                {t("output_inazuma_4")}
                <br />
                {t("output_inazuma_5")}
              </p>
            </div>
            <div className="modal-footer">
              <button
                data-test-id="button-comment-editor-cancel"
                type="button"
                className="btn btn-blue"
                onClick={this.hideGraphResult}
              >
                {t("close")}
              </button>
            </div>
          </Modal>
        )}
        {this.state.visibleDelayResult && (
          <Modal title={t("output_delay_list")} closeHandler={this.hideDelayResult}>
            <div className="modal-body modal-inazuma-confirm">
              <p>
                {t("output_delay_list_1")}
                <br />
                {t("output_delay_list_2")}
              </p>
              <p>
                <br />
                {t("output_inazuma_3")}
                <br />
                {t("output_inazuma_4")}
                <br />
                {t("output_inazuma_5")}
              </p>
            </div>
            <div className="modal-footer">
              <button
                data-test-id="button-comment-editor-cancel"
                type="button"
                className="btn btn-blue"
                onClick={this.hideDelayResult}
              >
                {t("close")}
              </button>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

Summary.propTypes = {
  fetching: PropTypes.bool.isRequired,
  search: PropTypes.func.isRequired,
  areaIds: PropTypes.array.isRequired,
  deviceIds: PropTypes.array.isRequired,
  categoryIds: PropTypes.array.isRequired,
  facilityManagementIds: PropTypes.array.isRequired,
  constructionManagementIds: PropTypes.array.isRequired,
  primaryChargeIds: PropTypes.array.isRequired,
  resetSearch: PropTypes.func.isRequired,
  summaries: PropTypes.array.isRequired,
  isInazumaSelecting: PropTypes.bool.isRequired,
  inazumaSelectedCells: PropTypes.array.isRequired,
  selectedCategoryIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedItemCount: PropTypes.number.isRequired,
  download: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  validated: PropTypes.bool.isRequired,
  revertLocalCondition: PropTypes.func.isRequired,
  beginSelectInazuma: PropTypes.func.isRequired,
  endSelectInazuma: PropTypes.func.isRequired,
  clearInazumaCells: PropTypes.func.isRequired,
  isGraphSelecting: PropTypes.bool.isRequired,
  graphSelectedCells: PropTypes.array.isRequired,
  beginSelectGraph: PropTypes.func.isRequired,
  endSelectGraph: PropTypes.func.isRequired,
  clearGraphCells: PropTypes.func.isRequired,
  selectedItemCountGraph: PropTypes.number.isRequired,
  createGraph: PropTypes.func.isRequired,
  isScheduleSelecting: PropTypes.bool.isRequired,
  selectedItemCountSchedule: PropTypes.number.isRequired,
  extensions: PropTypes.object.isRequired,
  beginSelectSchedule: PropTypes.func.isRequired,
  endSelectSchedule: PropTypes.func.isRequired,
  clearScheduleCells: PropTypes.func.isRequired,
  copyInazumaCell: PropTypes.func.isRequired,
  copyGraphCell: PropTypes.func.isRequired,
  copyScheduleCell: PropTypes.func.isRequired,
  isDelaySelecting: PropTypes.bool.isRequired,
  delaySelectedCells: PropTypes.array.isRequired,
  selectedItemCountDelay: PropTypes.number.isRequired,
  createDelayList: PropTypes.func.isRequired,
  copyDelayCell: PropTypes.func.isRequired,
  beginSelectDelay: PropTypes.func.isRequired,
  endSelectDelay: PropTypes.func.isRequired,
  clearDelayCells: PropTypes.func.isRequired,
  setConditions: PropTypes.func.isRequired,
};

export default withTranslation()(Summary);
