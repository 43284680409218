import moment from "moment/moment";
import React, { forwardRef, useCallback, useMemo } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

import { FormGrid, FormGroup, FormRow, Spacer } from "@/components/common";
import ChargeSelect from "@/components/common/ChargeSelect";
import { SearchConditionConfigModal } from "@/components/common/SearchCondition/SearchConditionConfigModal";
import { SearchConditionDisplayButton } from "@/components/common/SearchCondition/SearchConditionDisplayButton";
import MasterSelect from "@/components/common/Select";
import { SettingIconButton } from "@/components/common/SettingIconButton";
import { useSearchConditionConfig } from "@/hooks/useSearchConditionConfig";
import { ConstructionMasters } from "@/models/masters";
import {
  ScheduleOptionGroup,
  ScheduleChibaSearchKey,
  ScheduleChibaSearchKeyType,
  ScheduleChibaSearchParams,
} from "@/models/scheduleChiba";
import { SearchConditionConfigDefinition, ScreenName } from "@/models/screenConfig";

type Props = {
  masters: ConstructionMasters;
  scheduleDate?: string | Date | null;
  areaIds: number[];
  deviceIds: number[];
  categoryIds: number[];
  facilityManagementIds: number[];
  constructionManagementIds: number[];
  primaryChargeIds: number[];
  otherIds: number[];
  companyIds: number[];
  userIds: number[];
  groupIds: number[];
  field1Ids: number[];
  approveIds: number[];
  checkpoint1Id: number;
  checkpoint2Id: number;
  checkpoint3Id: number;
  checkpoint4Id: number;
  checkpoint5Id: number;
  checkpoint6Id: number;
  filter: number;
  requestUserId: number;
  field5Ids: number;
  onChangeScheduleDate: (d: Date) => void;
  onChangeArea: (v: number[]) => void;
  onChangeDevice: (v: number[]) => void;
  onChangeCategory: (v: number[]) => void;
  onChangeFacilityManagement: (v: number[]) => void;
  onChangeConstructionManagement: (v: number[]) => void;
  onChangePrimaryCharge: (v: number[]) => void;
  onChangeOther: (v: number[]) => void;
  onChangeCompany: (v: number[]) => void;
  onChangeUser: (v: number[]) => void;
  onChangeGroup: (v: number[]) => void;
  onChangeField1: (v: number[]) => void;
  onChangeApprove: (v: number[]) => void;
  onChangeCheckpoint1: (v: number) => void;
  onChangeCheckpoint2: (v: number) => void;
  onChangeCheckpoint3: (v: number) => void;
  onChangeCheckpoint4: (v: number) => void;
  onChangeCheckpoint5: (v: number) => void;
  onChangeCheckpoint6: (v: number) => void;
  onChangeFilter: (v?: number) => void;
  onChangeRequestUser: (v: number) => void;
  onChangeField5: (v: number) => void;
  onClear: () => void;
  onSearch: (scheduleDate: boolean, startPos: number, option?: { params?: ScheduleChibaSearchParams }) => void;
  searchConditionOptions: ScheduleOptionGroup[];
};

const OPTION_WITH_NAME = -1;
const OPTION_WITHOUT_NAME = -2;

export const ScheduleChibaSearchBox = forwardRef<HTMLDivElement, Props>(
  (
    {
      masters,
      scheduleDate,
      areaIds,
      deviceIds,
      categoryIds,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      companyIds,
      userIds,
      groupIds,
      field1Ids,
      approveIds,
      checkpoint1Id,
      checkpoint2Id,
      checkpoint3Id,
      checkpoint4Id,
      checkpoint5Id,
      checkpoint6Id,
      filter,
      requestUserId,
      field5Ids,
      onChangeScheduleDate,
      onChangeArea,
      onChangeDevice,
      onChangeCategory,
      onChangeFacilityManagement,
      onChangeConstructionManagement,
      onChangePrimaryCharge,
      onChangeOther,
      onChangeCompany,
      onChangeUser,
      onChangeGroup,
      onChangeField1,
      onChangeApprove,
      onChangeCheckpoint1,
      onChangeCheckpoint2,
      onChangeCheckpoint3,
      onChangeCheckpoint4,
      onChangeCheckpoint5,
      onChangeCheckpoint6,
      onChangeFilter,
      onChangeRequestUser,
      onChangeField5,
      onClear,
      onSearch,
      searchConditionOptions,
    },
    ref
  ) => {
    const { t } = useTranslation();

    // データ取り込み画面の検索条件設定の定義
    const scheduleChibaSearchConditionDefinitions = useMemo<
      SearchConditionConfigDefinition<ScheduleChibaSearchKeyType>[]
    >(
      () => [
        {
          itemsPerLine: 3,
          items: [
            { key: ScheduleChibaSearchKey.ScheduleDate, label: t("scheduled_date"), required: true },
            { key: ScheduleChibaSearchKey.Area, label: t("area") },
            { key: ScheduleChibaSearchKey.Device, label: t("device") },
            { key: ScheduleChibaSearchKey.Category, label: t("machines_category") },
            { key: ScheduleChibaSearchKey.FacilityManagement, label: t("facility_management") },
            { key: ScheduleChibaSearchKey.ConstructionManagement, label: t("construction_management") },
            { key: ScheduleChibaSearchKey.PrimaryCharge, label: t("primary_charge") },
            { key: ScheduleChibaSearchKey.Other, label: t("other") },
            { key: ScheduleChibaSearchKey.Company, label: t("construction_company") },
            { key: ScheduleChibaSearchKey.UserGroup, label: t("construction_pic") },
            { key: ScheduleChibaSearchKey.Checkpoint1, label: t("presence_request") },
            { key: ScheduleChibaSearchKey.Checkpoint2, label: t("new_keep") },
            { key: ScheduleChibaSearchKey.Checkpoint3, label: t("hot_work_direct") },
            { key: ScheduleChibaSearchKey.Checkpoint4, label: t("hot_work_indirect") },
            { key: ScheduleChibaSearchKey.Checkpoint5, label: t("container_work") },
            { key: ScheduleChibaSearchKey.Checkpoint6, label: t("abnormal_work") },
            { key: ScheduleChibaSearchKey.Field1, label: t("schedule_pic") },
            { key: ScheduleChibaSearchKey.RequestUser, label: t("request") },
            { key: ScheduleChibaSearchKey.Field5, label: t("pic_2") },
            { key: ScheduleChibaSearchKey.Approve, label: t("permission_approval") },
            { key: ScheduleChibaSearchKey.Filter, label: t("overtime_application") },
          ],
        },
      ],
      [t]
    );

    const config = useSearchConditionConfig<ScheduleChibaSearchKeyType>({
      screenName: ScreenName.SCHEDULE_CHIBA,
      requiredKeys: scheduleChibaSearchConditionDefinitions.flatMap((v) =>
        v.items.filter((item) => item.required).map((item) => item.key)
      ),
    });

    const handleSearch = () => {
      if (config.showAllConditions) {
        onSearch(false, 1);
      } else {
        onSearch(false, 1, {
          params: {
            areaIds: config.map.get(ScheduleChibaSearchKey.Area) ? areaIds : [],
            deviceIds: config.map.get(ScheduleChibaSearchKey.Device) ? deviceIds : [],
            categoryIds: config.map.get(ScheduleChibaSearchKey.Category) ? categoryIds : [],
            facilityManagementIds: config.map.get(ScheduleChibaSearchKey.FacilityManagement)
              ? facilityManagementIds
              : [],
            constructionManagementIds: config.map.get(ScheduleChibaSearchKey.ConstructionManagement)
              ? constructionManagementIds
              : [],
            primaryChargeIds: config.map.get(ScheduleChibaSearchKey.PrimaryCharge) ? primaryChargeIds : [],
            otherIds: config.map.get(ScheduleChibaSearchKey.Other) ? otherIds : [],
            companyParams: config.map.get(ScheduleChibaSearchKey.Company) ? companyIds : [],
            userParams: config.map.get(ScheduleChibaSearchKey.UserGroup) ? userIds : [],
            groupParams: config.map.get(ScheduleChibaSearchKey.UserGroup) ? groupIds : [],
            field1Params: config.map.get(ScheduleChibaSearchKey.Field1) ? field1Ids : [],
            approveParams: config.map.get(ScheduleChibaSearchKey.Approve) ? approveIds : [],
            checkpoint1: config.map.get(ScheduleChibaSearchKey.Checkpoint1) ? checkpoint1Id : 0,
            checkpoint2: config.map.get(ScheduleChibaSearchKey.Checkpoint2) ? checkpoint2Id : 0,
            checkpoint3: config.map.get(ScheduleChibaSearchKey.Checkpoint3) ? checkpoint3Id : 0,
            checkpoint4: config.map.get(ScheduleChibaSearchKey.Checkpoint4) ? checkpoint4Id : 0,
            checkpoint5: config.map.get(ScheduleChibaSearchKey.Checkpoint5) ? checkpoint5Id : 0,
            checkpoint6: config.map.get(ScheduleChibaSearchKey.Checkpoint6) ? checkpoint6Id : 0,
            filter: config.map.get(ScheduleChibaSearchKey.Filter) ? filter : 0,
            requestUser: config.map.get(ScheduleChibaSearchKey.RequestUser) ? requestUserId : 0,
            field5: config.map.get(ScheduleChibaSearchKey.Field5) ? field5Ids : 0,
          },
        });
      }
    };

    const convertOptions = useCallback(
      (target: string): { id: number; name: string; company_id?: string }[] => {
        return (
          searchConditionOptions
            .find((g) => g.target === target)
            ?.options.map((o) => ({ id: parseInt(o.id), name: o.name, company_id: o.company_id })) ?? []
        );
      },
      [searchConditionOptions]
    );

    // 検索条件の項目毎の選択肢
    const companyOptions = useMemo(() => convertOptions("company_id"), [searchConditionOptions]);
    const field1Options = useMemo(() => {
      const opts = convertOptions("field1");
      if (field1Ids.some((id) => id === OPTION_WITH_NAME || id === OPTION_WITHOUT_NAME)) {
        return opts.filter((o) => field1Ids.includes(o.id));
      }

      return opts;
    }, [searchConditionOptions, field1Ids]);
    const approveOptions = useMemo(() => {
      const opts = convertOptions("approve_user_id");
      if (approveIds.some((id) => id === OPTION_WITH_NAME || id === OPTION_WITHOUT_NAME)) {
        return opts.filter((o) => approveIds.includes(o.id));
      }

      return opts;
    }, [searchConditionOptions, approveIds]);
    const chargeOptions = useMemo(() => {
      const userListIds = convertOptions("user_id");
      const groupListIds = convertOptions("group_id");

      return {
        users: userListIds
          .filter((user) => {
            return companyIds.includes(parseInt(user.company_id));
          })
          .map((user) => {
            return { user_id: user.id, company_id: parseInt(user.company_id), user_name: user.name };
          }),
        groups: groupListIds
          .filter((group) => {
            return companyIds.includes(parseInt(group.company_id));
          })
          .map((group) => {
            return { group_id: group.id, company_id: parseInt(group.company_id), group_name: group.name };
          }),
      };
    }, [searchConditionOptions, companyIds]);
    const checkpoint1Options = useMemo(() => convertOptions("checkpoint1"), [searchConditionOptions]);
    const checkpoint2Options = useMemo(() => convertOptions("checkpoint2"), [searchConditionOptions]);
    const checkpoint3Options = useMemo(() => convertOptions("checkpoint3"), [searchConditionOptions]);
    const checkpoint4Options = useMemo(() => convertOptions("checkpoint4"), [searchConditionOptions]);
    const checkpoint5Options = useMemo(() => convertOptions("checkpoint5"), [searchConditionOptions]);
    const checkpoint6Options = useMemo(() => convertOptions("checkpoint6"), [searchConditionOptions]);
    const requestUserIdOptions = useMemo(() => convertOptions("request_user_id"), [searchConditionOptions]);
    const field5Options = useMemo(() => convertOptions("field5"), [searchConditionOptions]);

    const handleChangeCompany = (ids: number[]) => {
      if (ids.length > 0) {
        onChangeUser(
          chargeOptions.users
            .filter((user) => userIds.includes(user.user_id) && ids.includes(user.company_id))
            .map((user) => user.user_id)
        );
        onChangeGroup(
          chargeOptions.groups
            .filter((group) => userIds.includes(group.group_id) && ids.includes(group.company_id))
            .map((group) => group.group_id)
        );
      } else {
        onChangeUser([]);
        onChangeGroup([]);
      }
      onChangeCompany(ids);
    };

    const handleChangeApprove = (ids: number[]) => {
      if (ids.includes(OPTION_WITH_NAME) || ids.includes(OPTION_WITHOUT_NAME)) {
        onChangeApprove(ids.filter((id) => id === OPTION_WITH_NAME || id === OPTION_WITHOUT_NAME));
      } else {
        onChangeApprove(ids);
      }
    };

    const handleChangeField1 = (ids: number[]) => {
      if (ids.includes(OPTION_WITH_NAME) || ids.includes(OPTION_WITHOUT_NAME)) {
        onChangeField1(ids.filter((id) => id === OPTION_WITH_NAME || id === OPTION_WITHOUT_NAME));
      } else {
        onChangeField1(ids);
      }
    };

    return (
      <div ref={ref}>
        {!config.map ? (
          <></>
        ) : (
          <>
            <div className="flex justify-end bg-[#e4e4e4] w-[1200px]">
              <SettingIconButton className="w-[32px] p-[8px]" onClick={config.openSettingModal} />
            </div>
            <div className="search-box">
              <div className="grid gap-y-[16px]">
                <FormGrid
                  className="grid-cols-4"
                  display={config.show([
                    ScheduleChibaSearchKey.ScheduleDate,
                    ScheduleChibaSearchKey.Area,
                    ScheduleChibaSearchKey.Device,
                    ScheduleChibaSearchKey.Category,
                    ScheduleChibaSearchKey.FacilityManagement,
                    ScheduleChibaSearchKey.ConstructionManagement,
                    ScheduleChibaSearchKey.PrimaryCharge,
                    ScheduleChibaSearchKey.Other,
                    ScheduleChibaSearchKey.Company,
                    ScheduleChibaSearchKey.UserGroup,
                    ScheduleChibaSearchKey.Checkpoint1,
                    ScheduleChibaSearchKey.Checkpoint2,
                    ScheduleChibaSearchKey.Checkpoint3,
                    ScheduleChibaSearchKey.Checkpoint4,
                    ScheduleChibaSearchKey.Checkpoint5,
                    ScheduleChibaSearchKey.Checkpoint6,
                    ScheduleChibaSearchKey.Field1,
                    ScheduleChibaSearchKey.RequestUser,
                    ScheduleChibaSearchKey.Field5,
                    ScheduleChibaSearchKey.Approve,
                    ScheduleChibaSearchKey.Filter,
                  ])}
                >
                  <FormGroup title={t("scheduled_date")} className="schedule-date">
                    <DatePicker
                      selected={!scheduleDate ? null : moment(scheduleDate, "YYYY/MM/DD").toDate()}
                      dateFormat="yyyy/MM/dd"
                      locale={t("calender_locale")}
                      onChange={onChangeScheduleDate}
                    />
                  </FormGroup>
                  <FormGroup title={t("area")} display={config.show([ScheduleChibaSearchKey.Area])}>
                    <MasterSelect
                      prefix="area"
                      isMulti={true}
                      options={masters?.areas ?? []}
                      value={areaIds}
                      onChange={onChangeArea}
                    />
                  </FormGroup>
                  <FormGroup title={t("device")} display={config.show([ScheduleChibaSearchKey.Device])}>
                    <MasterSelect
                      prefix="device"
                      isMulti={true}
                      options={masters?.devices ?? []}
                      value={deviceIds}
                      onChange={onChangeDevice}
                    />
                  </FormGroup>
                  <FormGroup title={t("machines_category")} display={config.show([ScheduleChibaSearchKey.Category])}>
                    <MasterSelect
                      prefix="category"
                      isMulti={true}
                      options={masters?.categories ?? []}
                      value={categoryIds}
                      onChange={onChangeCategory}
                    />
                  </FormGroup>
                  <FormGroup
                    title={t("facility_management")}
                    display={config.show([ScheduleChibaSearchKey.FacilityManagement])}
                  >
                    <MasterSelect
                      prefix="facility_management"
                      isMulti={true}
                      options={masters?.facility_managements ?? []}
                      value={facilityManagementIds}
                      onChange={onChangeFacilityManagement}
                    />
                  </FormGroup>
                  <FormGroup
                    title={t("construction_management")}
                    display={config.show([ScheduleChibaSearchKey.ConstructionManagement])}
                  >
                    <MasterSelect
                      prefix="construction_management"
                      isMulti={true}
                      options={masters?.construction_managements ?? []}
                      value={constructionManagementIds}
                      onChange={onChangeConstructionManagement}
                    />
                  </FormGroup>
                  {/* 元請 */}
                  <FormGroup title={t("primary_charge")} display={config.show([ScheduleChibaSearchKey.PrimaryCharge])}>
                    <MasterSelect
                      prefix="primary_charge"
                      isMulti={true}
                      options={masters?.primary_charges ?? []}
                      value={primaryChargeIds}
                      onChange={onChangePrimaryCharge}
                    />
                  </FormGroup>
                  {/* その他 */}
                  <FormGroup title={t("other")} display={config.show([ScheduleChibaSearchKey.Other])}>
                    <MasterSelect
                      prefix="other"
                      isMulti={true}
                      options={masters?.others ?? []}
                      value={otherIds}
                      onChange={onChangeOther}
                    />
                  </FormGroup>
                  <FormGroup title={t("construction_company")} display={config.show([ScheduleChibaSearchKey.Company])}>
                    <MasterSelect
                      isMulti={true}
                      options={companyOptions}
                      value={companyIds}
                      onChange={handleChangeCompany}
                    />
                  </FormGroup>
                  <FormGroup title={t("construction_pic")} display={config.show([ScheduleChibaSearchKey.UserGroup])}>
                    <ChargeSelect
                      masters={chargeOptions}
                      userId={userIds}
                      groupId={groupIds}
                      isMulti={true}
                      onChange={(e) => {
                        const users = e ? e.filter((d) => d.category === "user") : [];
                        const groups = e ? e.filter((d) => d.category === "group") : [];

                        onChangeUser(users.map((u) => u.value));
                        onChangeGroup(groups.map((g) => g.value));
                      }}
                    />
                  </FormGroup>
                  <FormGroup title={t("presence_request")} display={config.show([ScheduleChibaSearchKey.Checkpoint1])}>
                    <MasterSelect
                      isMulti={false}
                      isClearable={true}
                      options={checkpoint1Options}
                      value={checkpoint1Id}
                      onChange={onChangeCheckpoint1}
                    />
                  </FormGroup>
                  <FormGroup title={t("new_keep")} display={config.show([ScheduleChibaSearchKey.Checkpoint2])}>
                    <MasterSelect
                      isMulti={false}
                      isClearable={true}
                      options={checkpoint2Options}
                      value={checkpoint2Id}
                      onChange={onChangeCheckpoint2}
                    />
                  </FormGroup>
                  <FormGroup title={t("hot_work_direct")} display={config.show([ScheduleChibaSearchKey.Checkpoint3])}>
                    <MasterSelect
                      isMulti={false}
                      isClearable={true}
                      options={checkpoint3Options}
                      value={checkpoint3Id}
                      onChange={onChangeCheckpoint3}
                    />
                  </FormGroup>
                  <FormGroup title={t("hot_work_indirect")} display={config.show([ScheduleChibaSearchKey.Checkpoint4])}>
                    <MasterSelect
                      isMulti={false}
                      isClearable={true}
                      options={checkpoint4Options}
                      value={checkpoint4Id}
                      onChange={onChangeCheckpoint4}
                    />
                  </FormGroup>
                  <FormGroup title={t("container_work")} display={config.show([ScheduleChibaSearchKey.Checkpoint5])}>
                    <MasterSelect
                      isMulti={false}
                      isClearable={true}
                      options={checkpoint5Options}
                      value={checkpoint5Id}
                      onChange={onChangeCheckpoint5}
                    />
                  </FormGroup>
                  <FormGroup title={t("abnormal_work")} display={config.show([ScheduleChibaSearchKey.Checkpoint6])}>
                    <MasterSelect
                      isMulti={false}
                      isClearable={true}
                      options={checkpoint6Options}
                      value={checkpoint6Id}
                      onChange={onChangeCheckpoint6}
                    />
                  </FormGroup>
                  <FormGroup
                    titleClassName="w-150"
                    title={t("schedule_pic")}
                    display={config.show([ScheduleChibaSearchKey.Field1])}
                  >
                    <MasterSelect
                      isMulti={true}
                      options={field1Options}
                      value={field1Ids}
                      onChange={handleChangeField1}
                    />
                  </FormGroup>
                  <FormGroup title={t("request")} display={config.show([ScheduleChibaSearchKey.RequestUser])}>
                    <MasterSelect
                      isMulti={false}
                      isClearable={true}
                      options={requestUserIdOptions}
                      value={requestUserId}
                      onChange={onChangeRequestUser}
                    />
                  </FormGroup>
                  <FormGroup title={t("pic_2")} display={config.show([ScheduleChibaSearchKey.Field5])}>
                    <MasterSelect
                      isMulti={false}
                      isClearable={true}
                      options={field5Options}
                      value={field5Ids}
                      onChange={onChangeField5}
                    />
                  </FormGroup>
                  <FormGroup title={t("permission_approval")} display={config.show([ScheduleChibaSearchKey.Approve])}>
                    <MasterSelect
                      isMulti={true}
                      options={approveOptions}
                      value={approveIds}
                      onChange={handleChangeApprove}
                    />
                  </FormGroup>
                  <FormGroup title="" display={config.show([ScheduleChibaSearchKey.Filter])}>
                    <label className="ckbox">
                      <input
                        type="checkbox"
                        value={1}
                        checked={filter === 1}
                        onChange={(e) => onChangeFilter(e.target.checked ? 1 : undefined)}
                      />
                      <span>{t("overtime_application")}</span>
                    </label>
                  </FormGroup>
                </FormGrid>
                <FormRow className="justify-between mt-[16px]">
                  <Spacer x="210px" />
                  <SearchConditionDisplayButton
                    isExpanded={config.showAllConditions}
                    onChange={config.toggleDisplayOptionalConditions}
                  />
                  <div className="flex gap-x-4">
                    <button data-test-id="button-schedule-reset" className="btn btn-gray" onClick={onClear}>
                      {t("reset")}
                    </button>
                    <button data-test-id="button-schedule-search" className="btn btn-blue" onClick={handleSearch}>
                      {t("search")}
                    </button>
                  </div>
                </FormRow>
              </div>
            </div>
          </>
        )}
        {config.showSettingModal && (
          <SearchConditionConfigModal<ScheduleChibaSearchKeyType>
            title={t("search_condition_config")}
            configMap={config.map}
            definitions={scheduleChibaSearchConditionDefinitions}
            onClose={config.closeSettingModal}
            onSubmit={(c) => config.saveSearchItemConfig(c)}
          />
        )}
      </div>
    );
  }
);

ScheduleChibaSearchBox.displayName = "ScheduleChibaSearchBox";
