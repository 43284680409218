import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { SingleValue, StylesConfig, components, OptionProps } from "react-select";

import { useFilterConfigSelectEvent } from "@/hooks/useFilterConfigSelectEvent";
import { FilterConfig } from "@/models/filterConfig";

type Props = {
  options: FilterConfig[];
  onChange: (o: FilterConfig) => void;
  onDelete: (o: FilterConfig) => void;
};

type SelectOption = { value: number; label: string };
const NONE = -1;
const NONE_VALUE: SelectOption = { value: NONE, label: "-----" };

export const FilterConfigSelect: FC<Props> = ({ options, onChange, onDelete }) => {
  const { t } = useTranslation();
  const selectOptions = useMemo<SelectOption[]>(() => {
    return [NONE_VALUE].concat(options.map((o) => ({ value: o.filter_config_id, label: o.label })));
  }, [options]);

  const [value, setValue] = useState<SingleValue<SelectOption>>(NONE_VALUE);
  useEffect(() => {
    if (!selectOptions.some((o) => o.value === value.value)) {
      setValue(NONE_VALUE);
    }
  }, [selectOptions, value]);
  useFilterConfigSelectEvent(() => setValue(NONE_VALUE));

  const handleChange = (e) => {
    setValue(e);
    0 < (e?.value ?? 0) && onChange(options.find((o) => o.filter_config_id === e.value));
  };

  const handleDelete = (o: SelectOption | SelectOption[]) => {
    const id = (Array.isArray(o) ? o[0] : o)?.value;
    if (id <= 0 || !id) return;
    onDelete(options.find((v) => v.filter_config_id === id));
  };

  return (
    <Select<SelectOption>
      styles={styles}
      value={value}
      options={selectOptions}
      onChange={handleChange}
      filterOption={(o) => 0 < Number(o.value)}
      isSearchable={false}
      components={{
        Option: ({ children, innerProps, ...props }: OptionProps) => {
          return (
            <components.Option {...props} innerProps={{ ...innerProps, style: { padding: "2px 5px" } }}>
              <div className="flex justify-between items-center">
                <div className="grow bg-white hover:bg-[#e0ebfd] rounded-[4px] p-[5px]">{children}</div>
                <div
                  className="ml-[5px] p-[5px] underline cursor-pointer"
                  onClick={(e) => {
                    handleDelete(props.data as SelectOption | SelectOption[]);
                    const v = props.getValue() as SelectOption[];
                    props.setValue(v[0], "select-option");
                    e.stopPropagation();
                  }}
                >
                  {t("remove")}
                </div>
              </div>
            </components.Option>
          );
        },
      }}
      menuPortalTarget={document.querySelector("body")}
    />
  );
};

const styles: StylesConfig = {
  control: (provided, _) => ({
    ...provided,
    borderRadius: 0,
    border: "1px solid rgba(0,0,0,.15)",
    minHeight: 27,
  }),
  dropdownIndicator: (provided, _) => ({
    ...provided,
    padding: 3,
  }),
  valueContainer: (provided, _) => ({
    ...provided,
    padding: "0 .45rem",
  }),
  option: (provided, _) => ({
    ...provided,
    color: "#111",
    textAlign: "left",
    backgroundColor: "#f0f0f0",
    "&:hover": {
      backgroundColor: "inherit",
    },
  }),
  menuList: (provided, _) => ({
    ...provided,
    backgroundColor: "#f0f0f0",
  }),
  menuPortal: (provided, _) => ({
    ...provided,
    zIndex: 9999,
  }),
};
