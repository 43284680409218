import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { addHeightResizeListener, removeHeightResizeListener, getTableBodyHeight } from "../../lib/common";
import restoreState from "../../lib/restoreState";
import storageManager from "../../lib/storageManager";
import Modal from "../Modal";

import CommentGridHeader from "./CommentGridHeader";
import CommentGridRow from "./CommentGridRow";
import CommentSearchArea from "./CommentSearchArea";

const autoload = false; // IEの二重リクエスト対策フラグ

class Comment extends Component {
  constructor(props) {
    super(props);
    const urlState = restoreState();
    const notFirst =
      storageManager.getConstructionItem("commentSearchParams") !== null || (urlState && urlState.hasQuery);
    this.state = {
      showSearch: true,
      showTable: false,
      isEmpty: false,
      tableBodyMaxHeight: window.innerHeight - 420,
      notFirst,
      heightListener: false,
      totalNum: 0,
      requestParam: [],

      currentPage: 1,
      maxPage: 0,
      limit: 20,
      start: 1,
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.showCommentList = this.showCommentList.bind(this);
    this.hideCommentList = this.hideCommentList.bind(this);

    this.handleSearchBoxHeightChange = this.handleSearchBoxHeightChange.bind(this);
    this.isScrollable = this.isScrollable.bind(this);
    this.handleResize = this.handleResize.bind(this);
    // this.fillList = this.fillList.bind(this);
  }

  componentDidMount() {
    this.resizeTimer = 0;
    window.addEventListener("resize", this.handleResize);

    const urlState = restoreState();
    const { validated } = this.props;
    if (
      validated &&
      (storageManager.getConstructionItem("commentSearchParams") !== null || (urlState && urlState.hasQuery))
    ) {
      this.handleSearch();
    }

    this.handleSearchBoxHeightChange();
    addHeightResizeListener(this.searchBox, this.handleSearchBoxHeightChange);
  }

  componentDidUpdate(prevProps) {
    const { validated } = this.props;
    if (!validated) {
      return;
    }

    const urlState = restoreState();
    const notFirst =
      storageManager.getConstructionItem("commentSearchParams") !== null || (urlState && urlState.hasQuery);
    if (!prevProps.validated && validated && notFirst) {
      this.handleSearch();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    removeHeightResizeListener(this.searchBox);

    this.props.revertLocalCondition();
  }

  // 検索ボックスの高さを設定する
  handleSearchBoxHeightChange() {
    const maxHeight = getTableBodyHeight("comment", this.searchBox, this.theader);
    this.setState({ tableBodyMaxHeight: maxHeight });
  }

  handleResize() {
    if (this.resizeTimer > 0) {
      clearTimeout(this.resizeTimer);
    }

    const callback = () => {
      this.handleSearchBoxHeightChange();
    };

    this.resizeTimer = setTimeout(callback, 200);
  }

  toggleSearch() {
    const callback = () => {
      this.handleSearchBoxHeightChange();
      addHeightResizeListener(this.searchBox, this.handleSearchBoxHeightChange);
    };
    this.setState({ showSearch: !this.state.showSearch }, () => setTimeout(callback, 100));
  }

  isScrollable() {
    const container = this.tbody;

    if (!container) {
      return false;
    }

    const diff = container.clientHeight - container.scrollHeight;

    return diff !== 0;
  }

  handleSearch() {
    this.setState({ isEmpty: true });

    console.log("handleSearch");

    const { categoryIds, comment, insertStartDate, insertEndDate } = this.props;

    const params = {
      categoryIds,
      comment,
      insertStartDate,
      insertEndDate,
      start: 1,
      limit: this.state.limit,
    };

    console.log({ params });
  }

  handleClear() {
    const target = {
      categoryIds: [],
      comment: "",
      insertStartDate: null,
      insertEndDate: null,
    };
    this.props.clearSearch(target);
    this.setState({ start: 1, showTable: false });
  }

  showCommentList() {
    this.setState({ visibleCommentList: true });
  }

  hideCommentList() {
    this.setState({ visibleCommentList: false });
  }

  handleDelete() {
    console.log("handleDelete");
    this.handleSearch();
  }

  handleSearchPager(startPos = 1) {
    this.setState({ isEmpty: true });
    const { clearSearch } = this.props;

    const { requestParam } = this.state;
    const params = {
      categoryIds: requestParam.category_id,
      comment: requestParam.comment,
      insertStartDate: requestParam.insert_start_date,
      insertEndDate: requestParam.insert_end_date,
      uploadTarget: requestParam.upload_target,
      start: startPos,
      limit: this.state.limit,
    };

    clearSearch(params);

    // search(params, (data) => {
    //   this.setState(
    //     {
    //       isEmpty: data.list.length === 0,
    //       maxPage: Math.ceil(data.total_num / this.state.limit),
    //       currentPage: Math.ceil(params.start / this.state.limit),
    //     },
    //     () => {
    //       this.showTable(this.fillList);
    //     }
    //   );
    // });
  }

  handleNextPage() {
    const _currentPage = this.state.currentPage;

    if (_currentPage < this.state.maxPage) {
      // const startPos = 1 + _currentPage * this.state.limit;
      // this.handleSearchPager(startPos);

      console.log(`handleNextPage`);
    }
  }

  handlePrevPage() {
    const _currentPage = this.state.currentPage;

    if (_currentPage > 1) {
      // const startPos = 1 + (_currentPage - 2) * this.state.limit;
      // this.handleSearchPager(startPos);

      console.log(`handlePrevPage`);
    }
  }

  render() {
    const { items, t, changeCategory, comment } = this.props;

    const titleClassName = `toggle icon-keyboard_arrow_up ${!this.state.showSearch ? "closed" : ""}`;

    return (
      <div>
        <div className="contents comment">
          <div className="inner">
            <h1 className="page-ttl">
              {t("comment_list")}
              <span
                data-test-id="button-qr-toggle-search"
                className={titleClassName}
                onClick={() => this.toggleSearch()}
              />
            </h1>
            {this.state.showSearch && (
              <CommentSearchArea
                {...this.props}
                reference={(node) => (this.searchBox = node)}
                handleSearch={this.handleSearch}
                handleClear={this.handleClear}
                changeCategory={changeCategory}
              />
            )}
            <div className={`tbl-comment`}>
              <p className="empty-message">{t("no_data")}</p>
              <div className="tbl-area tbl-comment-header tbl-head-adjusted" ref={(node) => (this.theader = node)}>
                <CommentGridHeader />
              </div>
              <div
                className={`tbl-area tbl-comment-body ${!this.state.showSearch ? "is-large" : ""}`}
                ref={(node) => (this.tbody = node)}
                style={{ maxHeight: this.state.tableBodyMaxHeight, overflowY: "scroll" }}
              >
                <table className="tbl-basic tbl-data comment-table-row">
                  <tbody className="tbl-area">
                    <CommentGridRow
                      handleDelete={this.handleDelete}
                      handleShowComment={this.showCommentList}
                      inputSearchComment={comment}
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {this.state.maxPage > 0 && (
          <div className="pagination">
            <div className="null-box grid1">
              {this.state.currentPage > 1 && (
                <div className="left-arrow-box" onClick={() => this.handlePrevPage()}>
                  <span>{t("prev")}</span>
                </div>
              )}
            </div>
            <span className="page-status">
              {this.state.currentPage} / {this.state.maxPage}
            </span>
            <div className="null-box grid3">
              {this.state.currentPage < this.state.maxPage && (
                <div className="right-arrow-box" onClick={() => this.handleNextPage()}>
                  <span>{t("next")}</span>
                </div>
              )}
            </div>
          </div>
        )}
        {this.state.visibleCommentList && (
          <Modal title={t("comment_list")} closeHandler={this.hideCommentList}>
            <div className="modal-body modal-comment-body">
              <p className="text-center">件名001：作業A</p>
              <ul className="modal-comment-list">
                <li className="modal-comment-listItem">
                  <p className="modal-comment-text">
                    この文章はダミーです。文字の大きさ、量、字間、行間等を確認するために入れています。
                  </p>
                  <div className="modal-comment-info">
                    <span className="modal-comment-createdAt">2023/09/01</span>
                    <span className="modal-comment-createdBy">FQ一般1</span>
                  </div>
                </li>
                <li className="modal-comment-listItem">
                  <p className="modal-comment-text">
                    この文章はダミーです。文字の大きさ、量、字間、行間等を確認するために入れています。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するために入れています。
                  </p>
                  <div className="modal-comment-info">
                    <span className="modal-comment-createdAt">2023/09/01</span>
                    <span className="modal-comment-createdBy">FQ一般1</span>
                  </div>
                </li>
                <li className="modal-comment-listItem">
                  <p className="modal-comment-text">
                    この文章はダミーです。文字の大きさ、量、字間、行間等を確認するために入れています。
                  </p>
                  <div className="modal-comment-info">
                    <span className="modal-comment-createdAt">2023/09/01</span>
                    <span className="modal-comment-createdBy">FQ一般1</span>
                  </div>
                </li>
                <li className="modal-comment-listItem">
                  <p className="modal-comment-text">
                    この文章はダミーです。文字の大きさ、量、字間、行間等を確認するために入れています。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するために入れています。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するために入れています。
                  </p>
                  <div className="modal-comment-info">
                    <span className="modal-comment-createdAt">2023/09/01</span>
                    <span className="modal-comment-createdBy">FQ一般1</span>
                  </div>
                </li>
              </ul>
            </div>
            <div className="modal-footer">
              <button
                data-test-id="button-comment-editor-cancel"
                type="button"
                className="btn btn-blue"
                onClick={this.hideCommentList}
              >
                {t("close")}
              </button>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

Comment.propTypes = {
  categoryIds: PropTypes.array.isRequired,
  masters: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  search: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Comment);
