import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { SubmitButton } from "@/components/common";

type Props = {
  onClick: () => void;
};

export const SaveFilterConfigButton: FC<Props> = ({ onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <SubmitButton onClick={onClick} style={{ height: "24px", padding: "0 7px" }}>
      {t("save_current_search_condition")}
    </SubmitButton>
  );
};
