import { connect } from "react-redux";

import actions from "../../actions/index";
import Comment from "../../components/comment/Comment";

const mapStateToProps = (state) => {
  let areas = [];
  let devices = [];
  let categories = [];
  let facilityManagements = [];
  let constructionManagements = [];
  let primaryCharges = [];
  let others = [];
  let processMajorClasses = [];
  let checkpoints = [];
  let systems = [];
  let companies = [];
  let users = [];
  let groups = [];

  if (state.construction.masters) {
    areas = state.construction.masters.areas;
    devices = state.construction.masters.devices;
    categories = state.construction.masters.categories;
    facilityManagements = state.construction.masters.facility_managements;
    constructionManagements = state.construction.masters.construction_managements;
    primaryCharges = state.construction.masters.primary_charges;
    others = state.construction.masters.others;
    processMajorClasses = state.construction.masters.process_major_classes;
    checkpoints = state.construction.masters.checkpoints;
    systems = state.construction.masters.systems;
    companies = state.construction.masters.companies;
    users = state.construction.masters.users;
    groups = state.construction.masters.groups;
  }

  return {
    masters: {
      areas,
      devices,
      categories,
      facilityManagements,
      constructionManagements,
      primaryCharges,
      others,
      processMajorClasses,
      checkpoints,
      systems,
      companies,
      users,
      groups,
    },
    categoryIds: state.comment.categoryIds,
    insertStartDate: state.comment.insertStartDate,
    insertEndDate: state.comment.insertEndDate,
    comment: state.comment.comment,
    items: state.comment.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCategory: (item) => dispatch(actions.comment.changeCategory(item)),
    changeComment: (comment) => dispatch(actions.comment.changeComment(comment)),
    search: (params, callback) => dispatch(actions.comment.search(params, callback)),
    clearSearch: (target) => dispatch(actions.comment.clearSearch(target)),
    changeDateFrom: (params, callback) => dispatch(actions.comment.changeDateFrom(params, callback)),
    changeDateTo: (params, callback) => dispatch(actions.comment.changeDateTo(params, callback)),
    revertLocalCondition: () => dispatch(actions.comment.revertLocalCondition()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Comment);
