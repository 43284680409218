import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Spacer } from "@/components/common";
import { FilterConfigSelect } from "@/components/common/SearchCondition";
import { FilterConfig } from "@/models/filterConfig";

type Props = {
  options: FilterConfig[];
  onSelect: (_: FilterConfig) => void;
  onDelete: (_: FilterConfig) => void;
};

export const FilterConfigSelectGroup: FC<Props> = ({ options, onSelect, onDelete }) => {
  const { t } = useTranslation();

  return (
    <div className="mx-[30px] flex items-center">
      <span>{t("saved_filters")}</span>
      <Spacer x="10px" />
      {options?.length ? (
        <div className="w-300">
          <FilterConfigSelect options={options} onChange={onSelect} onDelete={onDelete} />
        </div>
      ) : (
        <>{t("nothing")}</>
      )}
    </div>
  );
};
