import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import actions from "@/actions";
import ConfirmDialog from "@/components/ConfirmDialog";
import {
  FilterConfigSelectGroup,
  SaveFilterConfigButton,
  SaveFilterConfigModal,
} from "@/components/common/SearchCondition";
import { useFilterConfig } from "@/hooks/useFilterConfig";
import { FilterConfig } from "@/models/filterConfig";
import { ScreenNameType } from "@/models/screenConfig";

type Props = {
  screenName: ScreenNameType;
  getCurrentConditionLabels: () => { label: string; values: string[] }[];
  currentConfig: object;
  applyFilterConfig: (config: FilterConfig) => void;
};

export const FilterConfigGroup: FC<Props> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // マイ条件
  const filterConfig = useFilterConfig({ screenName: props.screenName });

  // マイ条件保存
  const [filterConfigToDisplay, setFilterConfigToDisplay] = useState<{ label: string; values: string[] }[]>();

  const handleOpenFilterConfigModal = () => {
    setFilterConfigToDisplay(props.getCurrentConditionLabels());
  };
  const handleCloseFilterConfig = () => {
    setFilterConfigToDisplay(null);
  };
  const handleSaveFilterConfig = (name: string) => {
    dispatch(actions.filterConfig.clearSelectedFilterConfig());
    filterConfig.create({ label: name, config: props.currentConfig });
    handleCloseFilterConfig();
  };

  // マイ条件適用
  const handleApplyFilterConfig = (config: FilterConfig) => {
    props.applyFilterConfig(config);
  };

  // マイ条件削除
  const [filterConfigToDelete, setFilterConfigToDelete] = useState<FilterConfig>();
  const handleOpenDeleteFilterConfigModal = (o: FilterConfig) => {
    setFilterConfigToDelete(o);
  };
  const handleDeleteFilterConfig = () => {
    filterConfig.remove(filterConfigToDelete);
    handleCancelDeleteFilterConfig();
  };
  const handleCancelDeleteFilterConfig = () => {
    setFilterConfigToDelete(null);
  };

  return (
    <>
      <div className="flex grow justify-between items-center">
        <FilterConfigSelectGroup
          options={filterConfig.values}
          onSelect={handleApplyFilterConfig}
          onDelete={handleOpenDeleteFilterConfigModal}
        />
        <SaveFilterConfigButton onClick={handleOpenFilterConfigModal} />
      </div>
      {filterConfigToDisplay && (
        <SaveFilterConfigModal
          conditionLabels={filterConfigToDisplay}
          onClose={handleCloseFilterConfig}
          onSubmit={handleSaveFilterConfig}
        />
      )}
      {filterConfigToDelete && (
        <ConfirmDialog
          title={t("confirmation")}
          messages={[filterConfigToDelete.label + t("delete_confirmation"), t("is_it_ok")]}
          okClickHandler={handleDeleteFilterConfig}
          cancelClickHandler={handleCancelDeleteFilterConfig}
        />
      )}
    </>
  );
};
