import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from "../../Modal";

import { Spacer } from "@/components/common";
import { SubmitButton } from "@/components/common/SubmitButton";

type Props = {
  conditionLabels: { label: string; values: string[] }[];
  onClose: () => void;
  onSubmit: (name: string) => void;
};

export const SaveFilterConfigModal = (props: Props) => {
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = () => {
    if (name.length === 0) {
      return setError(t("enter_name"));
    } else if (30 < name.length) {
      return setError(t("name_is_too_long_30"));
    }
    setError("");

    props.onSubmit(name);
    props.onClose();
  };

  return (
    <Modal title={t("save_search_condition")} closeHandler={props.onClose}>
      <div className="modal-body block w-[400px]">
        <div>
          <div className="flex items-center justify-center">
            <label>{t("name")}</label>
            <Spacer x="10px" />
            <input type="text" className="form-control w-240" value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          {error && (
            <div className="form-error flex" style={{ textAlign: "left" }}>
              <Spacer x="80px" />
              <p className="form-error-message">{error}</p>
            </div>
          )}
          <Spacer y="20px" />
          <div className="w-full">
            {props.conditionLabels.map((condition, i) => (
              <div key={i} className="flex items-start">
                <div className="text-right py-1 px-2 w-140 min-w-[140px]">
                  <div className="py-[5px]">{condition.label}：</div>
                </div>
                <div className="grow flex flex-wrap py-1 gap-[5px]">
                  {condition.values.map((value, j) => (
                    <div key={j} className="bg-[#e5e5e5] rounded p-[5px] break-all">
                      {value}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          data-test-id="button-search-panel-cancel"
          type="button"
          className="btn btn-gray"
          onClick={props.onClose}
        >
          {t("cancel")}
        </button>
        <SubmitButton onClick={handleSubmit} />
      </div>
    </Modal>
  );
};
