import { combineReducers } from "redux";

import * as constants from "../constants";

import app from "./app";
import approval from "./approval";
import area from "./area";
import asset from "./asset";
import category from "./category";
import checkpoint from "./checkpoint";
import comment from "./comment";
import construction from "./construction";
import constructionExtensions from "./construction_extensions";
import construction_management from "./construction_management";
import dataExport from "./dataExport";
import device from "./device";
import facility_management from "./facility_management";
import filterConfig from "./filterConfig";
import dataimport from "./import";
import information from "./information";
import inspection from "./inspection";
import login from "./login";
import matrix from "./matrix";
import matrixSearch from "./matrixSearch";
import notifications from "./notifications";
import other from "./other";
import primary_charge from "./primary_charge";
import process_major_class from "./process_major_class";
import process_middle_class from "./process_middle_class";
import progressNotification from "./progressNotification";
import qrcode from "./qrcode";
import schedule_chiba from "./schedule_chiba";
import schedule_chita from "./schedule_chita";
import schedule_eneos_kawasaki from "./schedule_eneos_kawasaki";
import session from "./session";
import summary from "./summary";
import system from "./system";

import { holiday } from "@/components/list/Holiday/reducers/holiday";
import { schedule } from "@/components/list/Schedule/reducers/schedule";
import { layoutNotifications } from "@/sx-layout/components/notification/reducers/notification";
import { plotmap } from "@/sx-layout/components/plotmap/reducers/plotmap";
import { machine } from "@/sx-layout/components/settings/machineSetting/reducers/machine";
import { plotPlan } from "@/sx-layout/components/settings/plotPlanSetting/reducers/plotPlan";
import { layoutApp } from "@/sx-layout/reducers/layoutApp";

const appReducer = combineReducers({
  login,
  app,
  information,
  construction,
  matrix,
  matrixSearch,
  summary,
  session,
  category,
  checkpoint,
  area,
  device,
  facility_management,
  construction_management,
  primary_charge,
  other,
  progressNotification,
  process_major_class,
  process_middle_class,
  dataimport,
  approval,
  inspection,
  schedule,
  schedule_eneos_kawasaki,
  schedule_chita,
  schedule_chiba,
  system,
  filterConfig,
  qrcode,
  asset,
  comment,
  dataExport,
  notifications,
  layoutApp,
  plotmap,
  plotPlan,
  machine,
  layoutNotifications,
  constructionExtensions,
  holiday,
});

const rootReducer = (state, action) => {
  if (action.type === constants.LOGOUT_SUCCESS) {
    state = undefined;
  } else if (action.type === constants.CONSTRUCTION_SAVE) {
    let key;

    for (key in state) {
      if (key !== "session") {
        state[key] = undefined;
      }
    }
  }

  return appReducer(state, action);
};

export default rootReducer;
