import PropTypes from "prop-types";
import React from "react";
import Highlighter from "react-highlight-words";
import { withTranslation } from "react-i18next";

import storageManager from "../../lib/storageManager";

const CommentGridRow = (props) => {
  const { t, handleDelete, handleShowComment, inputSearchComment } = props;

  return (
    <React.Fragment>
      <tr>
        <td rowSpan={4} className="w-110">
          テスト機器分類A
        </td>
        <td rowSpan={4} className="w-100">
          件名001
        </td>
        <td rowSpan={4} className="w-80">
          作業A
        </td>
        <td rowSpan={4} className="w-80 text-center">
          <a onClick={(e) => e.preventDefault()}>明細</a>
        </td>
        <td rowSpan={4} className="w-80 text-center">
          4
          <a className="ml-4" onClick={() => handleShowComment()}>
            表示
          </a>
        </td>
        <td>
          <Highlighter
            highlightClassName="comment-search-highlight"
            searchWords={[inputSearchComment]}
            autoEscape={true}
            textToHighlight={"この文章はダミーです。文字の大きさ、量、字間、行間等を確認するために入れています。"}
          />
        </td>
        <td className="w-160">2023/09/01 15:11:54</td>
        <td className="w-100">FQ一般1</td>
        <td className="w-80 text-center">
          <a onClick={(e) => e.preventDefault()}>編集</a>
        </td>
        <td className="w-80 text-center">
          <a onClick={() => handleDelete()}>削除</a>
        </td>
      </tr>
      <tr>
        <td>
          <Highlighter
            highlightClassName="comment-search-highlight"
            searchWords={[inputSearchComment]}
            autoEscape={true}
            textToHighlight={
              "この文章はダミーです。文字の大きさ、量、字間、行間等を確認するために入れています。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するために入れています。"
            }
          />
        </td>
        <td className="w-160">2023/09/01 15:11:54</td>
        <td className="w-100">FQ一般1</td>
        <td className="w-80 text-center">
          <a onClick={(e) => e.preventDefault()}>編集</a>
        </td>
        <td className="w-80 text-center">
          <a onClick={() => handleDelete()}>削除</a>
        </td>
      </tr>
      <tr>
        <td>
          <Highlighter
            highlightClassName="comment-search-highlight"
            searchWords={[inputSearchComment]}
            autoEscape={true}
            textToHighlight={"この文章はダミーです。文字の大きさ、量、字間、行間等を確認するために入れています。"}
          />
        </td>
        <td className="w-160">2023/09/01 15:11:54</td>
        <td className="w-100">FQ一般1</td>
        <td className="w-80 text-center">
          <a onClick={(e) => e.preventDefault()}>編集</a>
        </td>
        <td className="w-80 text-center">
          <a onClick={() => handleDelete()}>削除</a>
        </td>
      </tr>
      <tr>
        <td>
          <Highlighter
            highlightClassName="comment-search-highlight"
            searchWords={[inputSearchComment]}
            autoEscape={true}
            textToHighlight={
              "この文章はダミーです。文字の大きさ、量、字間、行間等を確認するために入れています。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するために入れています。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するために入れています。"
            }
          />
        </td>
        <td className="w-160">2023/09/01 15:11:54</td>
        <td className="w-100">FQ一般1</td>
        <td className="w-80 text-center">
          <a onClick={(e) => e.preventDefault()}>編集</a>
        </td>
        <td className="w-80 text-center">
          <a onClick={() => handleDelete()}>削除</a>
        </td>
      </tr>
    </React.Fragment>
  );
};

CommentGridRow.propTypes = {
  // item: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  handleShowComment: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  inputSearchComment: PropTypes.string.isRequired,
};

export default withTranslation()(CommentGridRow);
