// TODO constantsに移動する
/* 表示設定 */
export const ScreenName = {
  MATRIX: "matrix", // 進捗管理マトリクス
  IMPORT: "import", // データ登録
  SUMMARY: "summary", // 工事サマリー
  SYSTEM: "system", // 系統一覧
  APPROVAL: "approval", // 予定/承認リスト
  ASSET: "asset", // 資産一覧
  SCHEDULE: "schedule", // 電子予定表
  SCHEDULE_CHIBA: "schedule_chiba", // 予定表（OIREC千葉）
} as const;

export type ScreenNameType = (typeof ScreenName)[keyof typeof ScreenName];

export const ConfigType = {
  SEARCH_PANEL: 1,
  COLUMN: 2,
} as const;

export type ConfigTypeType = (typeof ConfigType)[keyof typeof ConfigType];

/* 表示設定 > 一覧検索条件設定 */
export type SearchConditionConfig = { [key: string]: 1 | 0 };

export type SearchConditionConfigDefinition<T> = {
  itemsPerLine: number;
  items: {
    key: T;
    label: string;
    required?: boolean;
  }[];
};
