import "react-datepicker/dist/react-datepicker.css";
import clsx from "clsx";
import format from "date-fns/format";
import moment from "moment/moment";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useEffectOnce } from "react-use";

import actions from "@/actions";
import { ExplanatoryNote } from "@/components/list/Schedule/components/ExplanatoryNote";
import { FireTypeAbbreviationNote } from "@/components/list/Schedule/components/FireTypeAbbreviationNote";
import PlanList from "@/components/list/Schedule/components/PlanList";
import { ResultList } from "@/components/list/Schedule/components/ResultList";
import { ScheduleExport } from "@/components/list/Schedule/components/ScheduleExport";
import { ScheduleStatusSwitcher } from "@/components/list/Schedule/components/ScheduleStatusSwitcher";
import { SearchBox } from "@/components/list/Schedule/components/SearchBox";
import { ContinuousUpdateStatus } from "@/components/list/Schedule/constants";
import { usePlanList } from "@/components/list/Schedule/hooks/usePlanList";
import { useResultList } from "@/components/list/Schedule/hooks/useResultList";
import { useSearchInput } from "@/components/list/Schedule/hooks/useSearchInput";
import {
  SearchInput,
  BulkUpdateOption,
  ContinuousUpdateStatusType,
  ScheduleConfig,
  ScheduleResult,
  ScheduleSchedule,
  SearchConditionOption,
} from "@/components/list/Schedule/types";
import { ConstructionMasters } from "@/models/masters";

type Props = {
  masters?: ConstructionMasters;
  bulkUpdateOptions?: BulkUpdateOption[];
  searchConditionOptions?: SearchConditionOption[];
  scheduleConfig?: ScheduleConfig;
  scheduleResults?: ScheduleResult;
  isLoadingResults: boolean;
  scheduleSchedules?: ScheduleSchedule;
  isLoadingSchedules: boolean;
};

export const Schedule: FC<Props> = ({
  masters,
  bulkUpdateOptions,
  searchConditionOptions,
  scheduleConfig,
  scheduleResults,
  isLoadingResults,
  scheduleSchedules,
  isLoadingSchedules,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // マスタデータ
  useEffectOnce(() => {
    // 選択肢取得
    dispatch(actions.schedule.getScheduleOptions());
  });

  const [scheduleDate, setScheduleDate] = useState<Date>(moment().add(1, "day").toDate());
  const [fetched, setFetched] = useState<boolean>(false);

  const condition = useSearchInput(scheduleDate);
  const [requestParam, setRequestParam] = useState<SearchInput>();
  const resultList = useResultList();
  const scheduleList = usePlanList();

  const [showSearch, setShowSearch] = useState(true);
  const [continuousUpdateStatus, setContinuousUpdateStatus] = useState<ContinuousUpdateStatusType>(
    ContinuousUpdateStatus.OFF
  );

  const [headerMessage, setHeaderMessage] = useState<string>("");
  useEffect(() => {
    // AM1:00〜5:00に予定未確定のメッセージを表示する
    const now = new Date();
    if (1 <= now.getHours() && now.getHours() < 5) {
      const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
      setHeaderMessage(format(tomorrow, t("schedule_is_not_confirmed")));
    }
  }, []);

  useEffect(() => {
    condition.hasStoredCondition && handleSearch();
  }, [condition.hasStoredCondition]);

  const toggleSearch = useCallback(() => {
    setShowSearch(!showSearch);
  }, [showSearch]);

  const handleReset = () => {
    condition.reset();
    const searchFilter = condition.defaultValues();
    setRequestParam(searchFilter);
    resultList.search(searchFilter);
    scheduleList.search(searchFilter);
  };

  const handleSearch = (option?: {
    planOnly?: boolean;
    page?: number;
    isPartialUpdate?: boolean;
    preventRefresh?: boolean;
    params?: SearchInput;
  }) => {
    let input = condition.values();
    if (option?.params) {
      input = option.params;
      condition.setValues(option.params);
    }
    setRequestParam(input);
    setScheduleDate(input.schedule_date);
    !option?.planOnly && resultList.search(input, option?.page);
    scheduleList.search(input, option);
    condition.saveLocal();
    setFetched(true);
  };

  return (
    <div className="inner schedule-container">
      <div className="tbl-top-area clearfix">
        <div className="tbl-top">
          <h1 className="page-ttl">
            {t("schedule_list")}
            <span
              data-test-id="button-schedule-toggle-search"
              className={`toggle icon-keyboard_arrow_up ${!showSearch ? "closed" : ""}`}
              onClick={() => toggleSearch()}
            />
          </h1>
        </div>
      </div>
      {headerMessage && <div className="text-[#cc0000] my-[5px]">{headerMessage}</div>}
      <div className={clsx("flex", showSearch ? "" : "hidden")}>
        <SearchBox
          scheduleDate={scheduleDate}
          input={condition}
          masters={masters}
          searchOptions={searchConditionOptions}
          onReset={handleReset}
          onSearch={handleSearch}
        />
        <div className="ml-20">
          <ExplanatoryNote />
        </div>
        <div className="ml-20">
          <FireTypeAbbreviationNote />
        </div>
      </div>
      <div className={clsx("h-[10px]", showSearch ? "" : "hidden")} />
      <div className="schedule-table-area">
        {!fetched ? (
          <></>
        ) : (!scheduleResults?.list || scheduleResults.list.length === 0) &&
          (!scheduleSchedules?.list || scheduleSchedules.list.length === 0) ? (
          <p className="empty-message">{t("no_data")}</p>
        ) : (
          <>
            <div className="tbl-top-area schedule-toolbar">
              <div className="w-[180px]">
                <ScheduleExport scheduleDate={scheduleDate} condition={requestParam} />
              </div>
              <div className="w-[170px] ml-[10px]">
                <ScheduleStatusSwitcher
                  value={continuousUpdateStatus}
                  onChange={(value) => setContinuousUpdateStatus(value)}
                />
              </div>
            </div>
            <div className="schedule-table-box">
              <ResultList
                data={scheduleResults}
                fetching={false}
                scheduleDate={scheduleDate}
                total={scheduleResults?.total_num ?? 0}
                onPrevPage={resultList.prev}
                onNextPage={resultList.next}
                onJumpPage={resultList.jump}
              />
              <div className="min-w-[10px]" />
              <PlanList
                data={scheduleSchedules}
                fetching={false}
                bulkUpdateOptions={bulkUpdateOptions}
                scheduleConfig={scheduleConfig}
                scheduleDate={scheduleDate}
                condition={condition}
                total={scheduleSchedules?.total_num ?? 0}
                continuousUpdateStatus={continuousUpdateStatus}
                onSearch={(option) => handleSearch({ planOnly: true, ...option })}
                onPrevPage={scheduleList.prev}
                onNextPage={scheduleList.next}
                onJumpPage={scheduleList.jump}
              />
            </div>
          </>
        )}
        {(isLoadingResults || isLoadingSchedules) && <div className={"loading"} />}
      </div>
    </div>
  );
};
