import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

const CommentGridHeader = (props) => {
  const { t } = props;

  return (
    <table className="tbl-basic tbl-data">
      <thead>
        <tr>
          <th>{t("machines_category")}</th>
          <th>{t("title")}</th>
          <th>{t("process_title")}</th>
          <th>{t("detail")}</th>
          <th>{t("comment_number")}</th>
          <th>{t("comment")}</th>
          <th>{t("created_at")}</th>
          <th>{t("created_by")}</th>
          <th>{t("edit")}</th>
          <th>{t("delete")}</th>
        </tr>
      </thead>
    </table>
  );
};

CommentGridHeader.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(CommentGridHeader);
