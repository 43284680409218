import * as constants from "../constants/index";
import environments from "../environments";
import storageManager from "../lib/storageManager";

import { SystemSearchParams } from "@/models/system";

export const changeArea = (items) => ({
  type: constants.SYSTEM_CHANGE_AREA,
  payload: items,
});

export const changeDevice = (items) => ({
  type: constants.SYSTEM_CHANGE_DEVICE,
  payload: items,
});

export const changeCategory = (item) => ({
  type: constants.SYSTEM_CHANGE_CATEGORY,
  payload: item || 0,
});

export const changeFacilityManagement = (items) => ({
  type: constants.SYSTEM_CHANGE_FACILITY_MANAGEMENT,
  payload: items,
});

export const changeConstructionManagement = (items) => ({
  type: constants.SYSTEM_CHANGE_CONSTRUCTION_MANAGEMENT,
  payload: items,
});

export const changePrimaryCharge = (items) => ({
  type: constants.SYSTEM_CHANGE_PRIMARY_CHARGE,
  payload: items,
});

export const changeOther = (items) => ({
  type: constants.SYSTEM_CHANGE_OTHER,
  payload: items,
});

export const changeScheduledDateFrom = (dateFrom) => ({
  type: constants.SYSTEM_CHANGE_SCHEDULED_DATE_FROM,
  payload: dateFrom
    ? dateFrom.getFullYear() +
      "-" +
      ("0" + (dateFrom.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + dateFrom.getDate()).slice(-2)
    : "",
});

export const changeScheduledDateTo = (dateTo) => ({
  type: constants.SYSTEM_CHANGE_SCHEDULED_DATE_TO,
  payload: dateTo
    ? dateTo.getFullYear() + "-" + ("0" + (dateTo.getMonth() + 1)).slice(-2) + "-" + ("0" + dateTo.getDate()).slice(-2)
    : "",
});

export const changeCloseDateFrom = (dateFrom) => ({
  type: constants.SYSTEM_CHANGE_CLOSE_DATE_FROM,
  payload: dateFrom
    ? dateFrom.getFullYear() +
      "-" +
      ("0" + (dateFrom.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + dateFrom.getDate()).slice(-2)
    : "",
});

export const changeCloseDateTo = (dateTo) => ({
  type: constants.SYSTEM_CHANGE_CLOSE_DATE_TO,
  payload: dateTo
    ? dateTo.getFullYear() + "-" + ("0" + (dateTo.getMonth() + 1)).slice(-2) + "-" + ("0" + dateTo.getDate()).slice(-2)
    : "",
});

export const changeSystemName = (systemName) => ({
  type: constants.SYSTEM_CHANGE_SYSTEMNAME,
  payload: systemName,
});

export const changeNote = (note) => ({
  type: constants.SYSTEM_CHANGE_NOTE,
  payload: note,
});

export const changeTotalTest = (totalTest) => ({
  type: constants.SYSTEM_CHANGE_TOTALTEST,
  payload: totalTest,
});

export const changePreparation = (preparation) => ({
  type: constants.SYSTEM_CHANGE_PREPARATION,
  payload: preparation,
});

export const resetSearch = () => {
  storageManager.removeConstructionItem("systemSearchParams");

  return {
    type: constants.SYSTEM_RESET_SEARCH,
  };
};

export const setValidated = (validated) => ({
  type: constants.SYSTEM_SET_VALIDATED,
  payload: validated,
});

export const revertLocalCondition = () => ({
  type: constants.SYSTEM_REVERT_CONDITION,
});

export const toggleDownloading = (downloading = false) => ({
  type: constants.SYSTEM_TOGGLE_DOWNLOADING,
  payload: downloading,
});

export const fetchItems = () => {
  const endpoint = `${environments.API_BASE_URI}/systems`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.SYSTEM_BEGIN_FETCH_ITEMS,
        };
      },
      success: (response) => {
        return {
          type: constants.SYSTEM_END_FETCH_ITEMS,
          payload: response.system,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const search = (params = null, callback) => {
  let endpoint = `${environments.API_BASE_URI}/systems`;
  const query = new URLSearchParams();

  //エリア
  if (params && params.areaIds.length > 0) {
    params.areaIds.forEach((param) => query.append("area_id", param));
  }
  //装置
  if (params && params.deviceIds.length > 0) {
    params.deviceIds.forEach((param) => query.append("device_id", param));
  }
  //機器分類
  if (params && params.categoryId > 0) {
    query.set("category_id", params.categoryId);
  }
  //設備管理
  if (params && params.facilityManagementIds.length > 0) {
    params.facilityManagementIds.forEach((param) => query.append("facility_management_id", param));
  }
  //工事管理
  if (params && params.constructionManagementIds.length > 0) {
    params.constructionManagementIds.forEach((param) => query.append("construction_management_id", param));
  }
  //元請
  if (params && params.primaryChargeIds.length > 0) {
    params.primaryChargeIds.forEach((param) => query.append("primary_charge_id", param));
  }
  //その他
  if (params && params.otherIds.length > 0) {
    params.otherIds.forEach((param) => query.append("other_id", param));
  }
  //系統名
  if (params && params.systemName) {
    query.set("system_name", params.systemName);
  }
  //備考
  if (params && params.note) {
    query.set("text", params.note);
  }
  //未実施/完了
  if (params && params.totalTest > -1) {
    query.set("totaltest_end", params.totalTest);
  }
  //気密準備
  if (!!params?.preparation || params?.preparation?.toString() === "false") {
    query.set("preparation_flg", params.preparation);
  }
  //予定日開始
  if (params && params.scheStartDate) {
    query.set("schedule_date_from", params.scheStartDate);
  }
  //予定日終了
  if (params && params.scheEndDate) {
    query.set("schedule_date_to", params.scheEndDate);
  }
  //完了日開始
  if (params && params.closeStartDate) {
    query.set("result_date_from", params.closeStartDate);
  }
  //完了日終了
  if (params && params.closeEndDate) {
    query.set("result_date_to", params.closeEndDate);
  }

  if (params && params.limit) {
    query.set("limit", params.limit);
  }

  if (params && params.start) {
    query.set("start", params.start);
  }

  if (query.toString() === "") {
    storageManager.removeConstructionItem("systemSearchParams");
  } else {
    storageManager.setConstructionItem("systemSearchParams", JSON.stringify(params));
    endpoint = `${endpoint}?${query.toString()}`;
  }

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.SYSTEM_BEGIN_SEARCH_ITEMS,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.SYSTEM_END_SEARCH_ITEMS,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const searchItems = (params = null, callback) => {
  let endpoint = `${environments.API_BASE_URI}/system/${params.systemId}/items`;
  const query = new URLSearchParams();
  let infinite = false;

  if (params && params.limit) {
    query.set("limit", params.limit);
  }

  if (params && params.start) {
    query.set("start", params.start);
  }

  if (params && params.start > 1) {
    infinite = true;
  }

  if (query.toString() !== "") {
    endpoint = `${endpoint}?${query.toString()}`;
  }

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: infinite
            ? constants.SYSTEM_BEGIN_SEARCH_DETAIL_ITEMS_INFINITE
            : constants.SYSTEM_BEGIN_SEARCH_DETAIL_ITEMS,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: infinite ? constants.SYSTEM_END_SEARCH_DETAIL_ITEMS_INFINITE : constants.SYSTEM_END_SEARCH_DETAIL_ITEMS,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const fetchItem = (systemId, callback) => {
  const endpoint = `${environments.API_BASE_URI}/systems/${systemId}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.SYSTEM_BEGIN_FETCH_ITEM,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.SYSTEM_END_FETCH_ITEM,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updateItem = (data, callback, failedCallback) => {
  return (dispatch, getState) => {
    const { system_id, system_name, totaltest_schedule_date, totaltest_result_date, system_comment, timestamp } = data;
    const action = {
      type: constants.APP_CALL_API,
      endpoint: `${environments.API_BASE_URI}/systems/update/${system_id}`,
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        system_name,
        totaltest_schedule_date,
        totaltest_result_date,
        system_comment,
        timestamp,
      }),
      callbacks: {
        success: (response) => {
          if (callback !== undefined) {
            callback(response);
          }

          return {
            type: constants.SYSTEM_END_UPDATE_ITEM,
            payload: response,
          };
        },
        error: (response) => {
          if (failedCallback !== undefined) {
            failedCallback();
          }

          return {
            type: constants.APP_SHOW_ERROR,
            payload: response,
            error: true,
          };
        },
      },
    };

    dispatch(action);
  };
};

export const setConditions = (params: SystemSearchParams) => ({
  type: constants.SYSTEM_SET_CONDITIONS,
  payload: params,
});
