import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import * as util from "../../lib/matrix";
import Modal from "../Modal";
import ChargeSelect from "../common/ChargeSelect";
import Select from "../common/Select";

import actions from "@/actions";
import { CheckBox, FormGrid, FormGroup, FormRow } from "@/components/common";
import {
  FilterConfigGroup,
  SearchConditionConfigModal,
  SearchConditionDisplayButton,
} from "@/components/common/SearchCondition";
import { SettingIconButton } from "@/components/common/SettingIconButton";
import { Spacer } from "@/components/common/Spacer";
import { NONE_VALUE } from "@/constants";
import { useSearchConditionConfig } from "@/hooks/useSearchConditionConfig";
import { FilterConfig } from "@/models/filterConfig";
import { ConstructionMasters } from "@/models/masters";
import { MatrixFilterConfig, MatrixSearchKey, MatrixSearchKeyType, MatrixSearchParams } from "@/models/matrix";
import { ScreenName, SearchConditionConfigDefinition } from "@/models/screenConfig";

type Props = {
  search: (option?: { params?: MatrixSearchParams }) => void;
  cancelHandler: (_) => void;
  closeHandler: () => void;
};

type DispatchProps = {
  masters: ConstructionMasters;
  searchParams: MatrixSearchParams;
  changeArea: (_) => void;
  changeDevice: (_) => void;
  changeCategory: (_) => void;
  changeFacilityManagement: (_) => void;
  changeConstructionManagement: (_) => void;
  changePrimaryCharge: (_) => void;
  changeOther: (_) => void;
  changeSystem: (_) => void;
  changeCompany: (_) => void;
  changeUser: (_) => void;
  changeGroup: (_) => void;
  changeProcessMiddleClass: (_) => void;
  changeItemText: (_) => void;
  changeProcessText: (_) => void;
  changeRegulation: (_) => void;
  changeFilter: (_) => void;
  clearConditions: () => void;
  showAlert: (title, message) => void;
  setConditions: (params: MatrixSearchParams) => void;
};

const SearchPanel: React.FC<Props> = (props: Props & DispatchProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // マトリクス画面の検索条件設定の定義
  const matrixSearchConditionDefinitions = useMemo<SearchConditionConfigDefinition<MatrixSearchKeyType>[]>(
    () => [
      {
        itemsPerLine: 3,
        items: [
          { key: MatrixSearchKey.Category, label: t("machines_category"), required: true },
          {
            key: MatrixSearchKey.PrimaryCharge,
            label: t("primary_charge"),
            required: true,
          },
        ],
      },
      {
        itemsPerLine: 3,
        items: [
          { key: MatrixSearchKey.Area, label: t("area") },
          { key: MatrixSearchKey.Device, label: t("device") },
          { key: MatrixSearchKey.FacilityManagement, label: t("facility_management") },
          { key: MatrixSearchKey.ConstructionManagement, label: t("construction_management") },
          { key: MatrixSearchKey.Other, label: t("other") },
          { key: MatrixSearchKey.Company, label: t("company") },
          { key: MatrixSearchKey.ProcessMiddleClass, label: t("matrix_search_middle") },
          { key: MatrixSearchKey.Regulation, label: t("law") },
        ],
      },
      {
        itemsPerLine: 1,
        items: [{ key: MatrixSearchKey.System, label: t("system") }],
      },
      {
        itemsPerLine: 1,
        items: [{ key: MatrixSearchKey.User, label: t("assignee") }],
      },
      {
        itemsPerLine: 1,
        items: [{ key: MatrixSearchKey.ItemText, label: t("title") }],
      },
      {
        itemsPerLine: 1,
        items: [{ key: MatrixSearchKey.ProcessText, label: t("process_title") }],
      },
      {
        itemsPerLine: 4,
        items: [
          { key: MatrixSearchKey.FilterComing, label: t("coming") },
          { key: MatrixSearchKey.FilterExpired, label: t("expired") },
          { key: MatrixSearchKey.FilterAssigned, label: t("assigned") },
          { key: MatrixSearchKey.FilterNotCompleted, label: t("not_completed") },
        ],
      },
    ],
    []
  );

  // 検索条件設定
  const config = useSearchConditionConfig<MatrixSearchKeyType>({
    screenName: ScreenName.MATRIX,
    requiredKeys: matrixSearchConditionDefinitions.flatMap((v) =>
      v.items.filter((item) => item.required).map((item) => item.key)
    ),
  });
  const searchConditionByConfig = (params: MatrixSearchParams): MatrixSearchParams => {
    if (!config.map || config.showAllConditions) return params;

    return {
      categoryId: config.map.get(MatrixSearchKey.Category) ? params.categoryId : 0,
      primaryChargeId: config.map.get(MatrixSearchKey.PrimaryCharge) ? params.primaryChargeId : 0,
      areaIds: config.map.get(MatrixSearchKey.Area) ? params.areaIds : [],
      deviceIds: config.map.get(MatrixSearchKey.Device) ? params.deviceIds : [],
      facilityManagementIds: config.map.get(MatrixSearchKey.FacilityManagement) ? params.facilityManagementIds : [],
      constructionManagementIds: config.map.get(MatrixSearchKey.ConstructionManagement)
        ? params.constructionManagementIds
        : [],
      otherIds: config.map.get(MatrixSearchKey.Other) ? params.otherIds : [],
      systemIds: config.map.get(MatrixSearchKey.System) ? params.systemIds : [],
      companyIds: config.map.get(MatrixSearchKey.Company) ? params.companyIds : [],
      userIds: config.map.get(MatrixSearchKey.User) ? params.userIds : [],
      groupIds: config.map.get(MatrixSearchKey.User) ? params.groupIds : [],
      processMiddleClassIds: config.map.get(MatrixSearchKey.ProcessMiddleClass) ? params.processMiddleClassIds : [],
      itemText: config.map.get(MatrixSearchKey.ItemText) ? params.itemText : "",
      processText: config.map.get(MatrixSearchKey.ProcessText) ? params.processText : "",
      regulation: config.map.get(MatrixSearchKey.Regulation) ? params.regulation : "",
      filters: [
        config.map.get(MatrixSearchKey.FilterComing) && (params.filters ?? []).includes(1) ? 1 : undefined,
        config.map.get(MatrixSearchKey.FilterExpired) && (params.filters ?? []).includes(2) ? 2 : undefined,
        config.map.get(MatrixSearchKey.FilterAssigned) && (params.filters ?? []).includes(3) ? 3 : undefined,
        config.map.get(MatrixSearchKey.FilterNotCompleted) && (params.filters ?? []).includes(4) ? 4 : undefined,
      ].filter((v) => !!v),
    };
  };

  // 検索条件保存
  const getCurrentConditionLabels = () => {
    const { masters: m, searchParams } = props;
    const p = searchConditionByConfig(searchParams);
    // 現在の条件を保存モーダルに表示するために編集する
    const withNone = (ids: number[]): string | undefined => (ids.includes(NONE_VALUE) ? t("not_set") : undefined);
    const names: { label: string; values: string[] | null }[] = [
      {
        label: t("machines_category"),
        values: [m.categories.find((v) => v.category_id === p.categoryId)?.category_name],
      },
      {
        label: t("primary_charge"),
        values: [m.primary_charges.find((v) => v.primary_charge_id === p.primaryChargeId)?.primary_charge_name],
      },
      {
        label: t("area"),
        values: m.areas
          .filter((v) => p.areaIds.includes(v.area_id))
          .map((v) => v.area_name)
          .concat(withNone(p.areaIds)),
      },
      {
        label: t("device"),
        values: m.devices
          .filter((v) => p.deviceIds.includes(v.device_id))
          .map((v) => v.device_name)
          .concat(withNone(p.deviceIds)),
      },
      {
        label: t("facility_management"),
        values: m.facility_managements
          .filter((v) => p.facilityManagementIds.includes(v.facility_management_id))
          .map((v) => v.facility_management_name)
          .concat(withNone(p.facilityManagementIds)),
      },
      {
        label: t("construction_management"),
        values: m.construction_managements
          .filter((v) => p.constructionManagementIds.includes(v.construction_management_id))
          .map((v) => v.construction_management_name)
          .concat(withNone(p.constructionManagementIds)),
      },
      {
        label: t("other"),
        values: m.others
          .filter((v) => p.otherIds.includes(v.other_id))
          .map((v) => v.other_name)
          .concat(withNone(p.otherIds)),
      },
      {
        label: t("company"),
        values: m.companies.filter((v) => p.companyIds.includes(v.company_id)).map((v) => v.company_name),
      },
      {
        label: t("matrix_search_middle"),
        values: m.process_middle_classes
          .filter((v) => p.processMiddleClassIds.includes(v.process_middle_class_id))
          .map((v) => v.process_middle_class_name),
      },
      {
        label: t("law"),
        values: [p.regulation],
      },
      {
        label: t("system"),
        values: m.systems.filter((v) => p.systemIds.includes(v.system_id)).map((v) => v.system_name),
      },
      {
        label: t("assignee"),
        values: [
          ...m.users.filter((v) => p.userIds.includes(v.user_id)).map((v) => v.user_name),
          ...m.groups.filter((v) => p.groupIds.includes(v.group_id)).map((v) => v.group_name),
        ],
      },
      {
        label: t("title"),
        values: [p.itemText],
      },
      {
        label: t("process_title"),
        values: [p.processText],
      },
      {
        label: t("check"),
        values: [
          p.filters?.includes(1) ? t("coming") : "",
          p.filters?.includes(2) ? t("expired") : "",
          p.filters?.includes(3) ? t("assigned") : "",
          p.filters?.includes(4) ? t("not_completed") : "",
        ],
      },
    ];

    return names.map((n) => ({ label: n.label, values: _.compact(n.values) })).filter((n) => n.values?.length);
  };
  const currentSearchParams = useMemo<MatrixFilterConfig>(() => {
    const s = searchConditionByConfig(props.searchParams);

    return {
      category_id: s.categoryId,
      primary_charge_id: s.primaryChargeId,
      area_id: s.areaIds,
      device_id: s.deviceIds,
      facility_management_id: s.facilityManagementIds,
      construction_management_id: s.constructionManagementIds,
      other_id: s.otherIds,
      system_id: s.systemIds,
      company_id: s.companyIds,
      user_id: s.userIds,
      group_id: s.groupIds,
      process_middle_class_ids: s.processMiddleClassIds,
      item_text: s.itemText,
      process_text: s.processText,
      regulation: s.regulation,
      filter: s.filters,
    };
  }, [props.searchParams, config.showAllConditions, config.map]);
  const handleApplyFilterConfig = (filter: FilterConfig<MatrixFilterConfig>) => {
    const f = filter.config;
    const params: MatrixSearchParams = {
      categoryId: f.category_id,
      primaryChargeId: f.primary_charge_id,
      areaIds: f.area_id,
      deviceIds: f.device_id,
      facilityManagementIds: f.facility_management_id,
      constructionManagementIds: f.construction_management_id,
      otherIds: f.other_id,
      systemIds: f.system_id,
      companyIds: f.company_id,
      userIds: f.user_id,
      groupIds: f.group_id,
      processMiddleClassIds: f.process_middle_class_ids,
      itemText: f.item_text,
      processText: f.process_text,
      regulation: f.regulation,
      filters: f.filter,
    };
    // 検索条件設定で表示している検索条件のみ復元する
    props.setConditions(searchConditionByConfig(params));
  };

  // 検索
  const handleSearch = (e) => {
    e.preventDefault();
    search(props.searchParams);
  };
  const search = (params) => {
    if (!params.primaryChargeId || params.primaryChargeId === 0) {
      props.showAlert(t("error"), [t("select_primary_charge")]);
    } else {
      if (config.showAllConditions) {
        props.search();
      } else {
        // 検索条件設定で表示している検索条件のみ有効とする
        props.search({ params: searchConditionByConfig(params) });
      }
      props.closeHandler();
    }
  };

  const {
    masters,
    searchParams,
    changeArea,
    changeDevice,
    changeCategory,
    changeFacilityManagement,
    changeConstructionManagement,
    changePrimaryCharge,
    changeOther,
    changeSystem,
    changeItemText,
    changeProcessText,
    changeRegulation,
    changeFilter,
    changeUser,
    changeGroup,
    changeCompany,
    changeProcessMiddleClass,
  } = props;

  // 機器分類と元請の連動
  const primaryChargesForCategory = useMemo(() => {
    const categoryPrimaryCharge = masters?.categories_primary_charges?.find(
      (category) => category.category_id === searchParams.categoryId
    );

    return categoryPrimaryCharge?.primary_charges ?? [];
  }, [masters?.categories_primary_charges, searchParams.categoryId]);
  useEffect(() => {
    if (!primaryChargesForCategory.some((pc) => pc.primary_charge_id === searchParams.primaryChargeId)) {
      changePrimaryCharge(primaryChargesForCategory[0]?.primary_charge_id ?? 0);
    }
  }, [changePrimaryCharge, primaryChargesForCategory, searchParams.primaryChargeId]);

  if (!config.map || !masters) return <></>;

  return (
    <>
      <Modal title={t("matrix_search")} closeHandler={props.closeHandler}>
        <div className="flex justify-between items-center bg-[#e4e4e4] w-full">
          <FilterConfigGroup
            screenName={ScreenName.MATRIX}
            getCurrentConditionLabels={getCurrentConditionLabels}
            currentConfig={currentSearchParams}
            applyFilterConfig={handleApplyFilterConfig}
          />
          <SettingIconButton className="w-[32px] p-[8px] " onClick={config.openSettingModal} />
        </div>
        <div className="modal-body w-880 clearfix">
          <div className="grid gap-y-[16px]">
            <FormRow>
              <FormGroup title={t("machines_category")} className="w-340">
                <Select
                  prefix="category"
                  options={masters.categories}
                  value={searchParams.categoryId}
                  onChange={changeCategory}
                />
              </FormGroup>
              <FormGroup title={t("primary_charge")} className="w-260">
                <Select
                  prefix="primary_charge"
                  options={primaryChargesForCategory}
                  value={searchParams.primaryChargeId}
                  onChange={changePrimaryCharge}
                />
              </FormGroup>
            </FormRow>
            <FormGrid
              className="grid-cols-3"
              display={config.show([
                MatrixSearchKey.Area,
                MatrixSearchKey.Device,
                MatrixSearchKey.FacilityManagement,
                MatrixSearchKey.ConstructionManagement,
                MatrixSearchKey.Other,
                MatrixSearchKey.Company,
                MatrixSearchKey.ProcessMiddleClass,
                MatrixSearchKey.Regulation,
              ])}
            >
              <FormGroup title={t("area")} className="w-260" display={config.show([MatrixSearchKey.Area])}>
                <Select
                  prefix="area"
                  options={masters.areas}
                  value={searchParams.areaIds}
                  onChange={changeArea}
                  isMulti={true}
                  withNone
                />
              </FormGroup>
              <FormGroup title={t("device")} className="w-260" display={config.show([MatrixSearchKey.Device])}>
                <Select
                  prefix="device"
                  options={masters.devices}
                  value={searchParams.deviceIds}
                  onChange={changeDevice}
                  isMulti={true}
                  withNone
                />
              </FormGroup>
              <FormGroup
                title={t("facility_management")}
                className="w-260"
                display={config.show([MatrixSearchKey.FacilityManagement])}
              >
                <Select
                  prefix="facility_management"
                  options={masters.facility_managements}
                  value={searchParams.facilityManagementIds}
                  onChange={changeFacilityManagement}
                  isMulti={true}
                  withNone
                />
              </FormGroup>
              <FormGroup
                title={t("construction_management")}
                className="w-260"
                display={config.show([MatrixSearchKey.ConstructionManagement])}
              >
                <Select
                  prefix="construction_management"
                  options={masters.construction_managements}
                  value={searchParams.constructionManagementIds}
                  onChange={changeConstructionManagement}
                  isMulti={true}
                  withNone
                />
              </FormGroup>
              <FormGroup title={t("other")} className="w-260" display={config.show([MatrixSearchKey.Other])}>
                <Select
                  prefix="other"
                  options={masters.others}
                  value={searchParams.otherIds}
                  onChange={changeOther}
                  isMulti={true}
                  withNone
                />
              </FormGroup>
              <FormGroup title={t("company")} className="w-260" display={config.show([MatrixSearchKey.Company])}>
                <Select
                  prefix="company"
                  options={masters.companies}
                  value={searchParams.companyIds}
                  onChange={changeCompany}
                  isMulti={true}
                />
              </FormGroup>
              <FormGroup
                title={t("matrix_search_middle")}
                className="w-260"
                display={config.show([MatrixSearchKey.ProcessMiddleClass])}
              >
                <Select
                  prefix="process_middle_class"
                  options={masters.process_middle_classes}
                  value={searchParams.processMiddleClassIds}
                  onChange={changeProcessMiddleClass}
                  isMulti={true}
                />
              </FormGroup>
              <FormGroup title={t("law")} className="w-260" display={config.show([MatrixSearchKey.Regulation])}>
                <input
                  data-test-id="text-search-panel-regulation"
                  type="text"
                  className="form-control w-170"
                  value={searchParams.regulation}
                  onChange={(e) => changeRegulation(e.target.value)}
                />
              </FormGroup>
            </FormGrid>
            <FormRow display={config.show([MatrixSearchKey.System])}>
              <FormGroup title={t("system")} className="w-340">
                <Select
                  prefix="system"
                  options={masters.systems}
                  value={searchParams.systemIds}
                  onChange={changeSystem}
                  isMulti={true}
                />
              </FormGroup>
            </FormRow>
            <FormRow display={config.show([MatrixSearchKey.User])}>
              <FormGroup title={t("assignee")} className="w-340">
                <ChargeSelect
                  masters={masters}
                  userId={searchParams.userIds}
                  groupId={searchParams.groupIds}
                  isMulti={true}
                  onChange={(e) => {
                    const users = e ? e.filter((d) => d.category === "user") : [];
                    const groups = e ? e.filter((d) => d.category === "group") : [];

                    changeUser(users.map((u) => u.value));
                    changeGroup(groups.map((g) => g.value));
                  }}
                />
              </FormGroup>
            </FormRow>
            <FormRow display={config.show([MatrixSearchKey.ItemText])}>
              <FormGroup title={t("title")} className="w-520">
                <input
                  data-test-id="text-search-panel-item-name"
                  type="text"
                  className="form-control w-430"
                  value={searchParams.itemText}
                  onChange={(e) => changeItemText(e.target.value)}
                />
              </FormGroup>
            </FormRow>
            <FormRow display={config.show([MatrixSearchKey.ProcessText])}>
              <FormGroup title={t("process_title")} className="w-520">
                <input
                  data-test-id="text-search-panel-process-name"
                  type="text"
                  className="form-control w-430"
                  value={searchParams.processText}
                  onChange={(e) => changeProcessText(e.target.value)}
                />
              </FormGroup>
            </FormRow>
            <FormRow
              className="gap-x-[15px]"
              display={config.show([
                MatrixSearchKey.FilterComing,
                MatrixSearchKey.FilterExpired,
                MatrixSearchKey.FilterAssigned,
                MatrixSearchKey.FilterNotCompleted,
              ])}
            >
              <Spacer x="60px" />
              {config.show([MatrixSearchKey.FilterComing]) && (
                <CheckBox
                  data-test-id="checkbox-search-panel-coming"
                  checked={_.includes(searchParams.filters, 1)}
                  label={t("coming")}
                  onChange={() => changeFilter(1)}
                />
              )}
              {config.show([MatrixSearchKey.FilterExpired]) && (
                <CheckBox
                  data-test-id="checkbox-search-panel-expired"
                  checked={_.includes(searchParams.filters, 2)}
                  label={t("expired")}
                  onChange={() => changeFilter(2)}
                />
              )}
              {config.show([MatrixSearchKey.FilterAssigned]) && (
                <CheckBox
                  data-test-id="checkbox-search-panel-assigned"
                  checked={_.includes(searchParams.filters, 3)}
                  label={t("assigned")}
                  onChange={() => changeFilter(3)}
                />
              )}
              {config.show([MatrixSearchKey.FilterNotCompleted]) && (
                <CheckBox
                  data-test-id="checkbox-search-panel-not-completed"
                  checked={_.includes(searchParams.filters, 4)}
                  label={t("not_completed")}
                  onChange={() => changeFilter(4)}
                />
              )}
            </FormRow>
          </div>
          <div className="flex justify-center mt-[20px]">
            <SearchConditionDisplayButton
              isExpanded={config.showAllConditions}
              onChange={config.toggleDisplayOptionalConditions}
            />
          </div>
        </div>
        <div className="modal-footer">
          <button
            data-test-id="button-search-panel-cancel"
            type="button"
            className="btn btn-gray"
            onClick={props.cancelHandler}
          >
            {t("cancel")}
          </button>
          <button
            data-test-id="button-search-panel-clear"
            type="button"
            className="btn btn-gray"
            onClick={() => {
              util.removeItemIdParam();
              props.clearConditions();
              dispatch(actions.filterConfig.clearSelectedFilterConfig());
            }}
          >
            {t("clear")}
          </button>
          <button
            data-test-id="button-search-panel-search"
            type="button"
            className="btn btn-blue"
            onClick={(e) => {
              util.removeItemIdParam();
              util.removeProcessIdParam();
              handleSearch(e);
            }}
          >
            {t("search")}
          </button>
        </div>
      </Modal>
      {config.showSettingModal && (
        <SearchConditionConfigModal<MatrixSearchKeyType>
          title={t("matrix") + t("search_condition_config")}
          configMap={config.map}
          definitions={matrixSearchConditionDefinitions}
          onClose={config.closeSettingModal}
          onSubmit={(c) => config.saveSearchItemConfig(c)}
        />
      )}
    </>
  );
};

export default withTranslation()(SearchPanel);
