import PropTypes from "prop-types";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { withTranslation } from "react-i18next";

import MasterSelect from "../common/Select";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import { removeHeightResizeListener } from "../../lib/common";

class CommentSearchArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: [],
    };

    this.handleChangeDateFrom = this.handleChangeDateFrom.bind(this);
    this.handleChangeDateTo = this.handleChangeDateTo.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleClear = this.handleClear.bind(this);
  }

  handleChangeDateFrom(date) {
    this.props.changeDateFrom(
      date
        ? date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)
        : ""
    );
  }

  handleChangeDateTo(date) {
    this.props.changeDateTo(
      date
        ? date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)
        : ""
    );
  }

  handleSearch() {
    const error = [];
    const { comment, handleSearch, insertStartDate, insertEndDate } = this.props;

    // 日付のチェック
    if (insertStartDate !== null && insertEndDate !== null) {
      const sd = moment(insertStartDate);
      const ed = moment(insertEndDate);
      error.insertDate = ed.diff(sd, "days") < 0 ? true : false;
    }

    error.comment = comment.length > 100 ? true : false;
    if (!error.comment) {
      handleSearch();
    }
    this.setState({ error });
  }

  handleClear() {
    this.props.handleClear();
    this.setState({ error: [] });
  }

  render() {
    const {
      masters,
      categoryIds,
      insertStartDate,
      insertEndDate,
      changeCategory,
      comment,
      changeComment,
      reference,
      t,
    } = this.props;

    const { error } = this.state;

    return (
      <div className="search-box" ref={reference}>
        <div className="form-row">
          <div className="form-group w-360">
            <span className="form-label">{t("machines_category")}</span>
            <div className="w-270 d-ib ta-l">
              <MasterSelect
                prefix="category"
                isMulti={true}
                options={masters.categories}
                value={categoryIds}
                onChange={changeCategory}
              />
            </div>
          </div>
          <div className="form-group w-330">
            <span className="form-label">{t("comment")}</span>
            <input
              data-test-id="text-import-file-name"
              type="text"
              className="form-control w-230"
              value={comment}
              onChange={(e) => changeComment(e.target.value)}
            />
          </div>
        </div>
        <div className="form-row mb-0">
          <div className="form-group w-360">
            <span className="form-label">{t("registered_day")}</span>
            <div className="d-ib">
              <DatePicker
                wrapperClassName="w-120"
                disabled={false}
                selected={insertStartDate ? moment(insertStartDate).toDate() : null}
                dateFormat="yyyy/MM/dd"
                locale={t("calender_locale")}
                onChange={(date) => this.handleChangeDateFrom(date)}
              />
            </div>
            <div className="d-ib w-30 ta-c">&nbsp;〜&nbsp;</div>
            <div className="d-ib">
              <DatePicker
                wrapperClassName="w-120"
                disabled={false}
                selected={insertEndDate ? moment(insertEndDate).toDate() : null}
                dateFormat="yyyy/MM/dd"
                locale={t("calender_locale")}
                onChange={(date) => this.handleChangeDateTo(date)}
              />
            </div>
            {error.insertDate && (
              <div className="form-error w-380">
                <p className="form-error-message w-300">{t("schedule_alert")}</p>
              </div>
            )}
          </div>
          <div className="form-group btn-area pull-right">
            <button data-test-id="button-import-reset" className="btn btn-gray" onClick={() => this.handleClear()}>
              {t("reset")}
            </button>
            <button data-test-id="button-import-search" className="btn btn-blue" onClick={() => this.handleSearch()}>
              {t("search")}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

CommentSearchArea.propTypes = {
  categoryIds: PropTypes.array.isRequired,
  masters: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  changeCategory: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
  reference: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(CommentSearchArea);
