import * as constants from "../constants/index";
import restoreState from "../lib/restoreState";
import storageManager from "../lib/storageManager";

const initialState = {
  categoryIds: [],
  comment: "",
  insertStartDate: "",
  insertEndDate: "",
  items: [],
};

const getInitialSearchConditions = (onlyLocal = false) => {
  const urlState = restoreState();
  let categoryIds = [];
  let comment = "";
  let insertStartDate = "";
  let insertEndDate = "";

  if (storageManager.getConstructionItem("commentSearchParams") !== null) {
    const params = JSON.parse(storageManager.getConstructionItem("commentSearchParams"));

    categoryIds = params.categoryIds;
    comment = params.comment;
    insertStartDate = params.insertStartDate;
    insertEndDate = params.insertEndDate;
  }

  if (!onlyLocal && urlState && urlState.menu === "comment") {
    categoryIds = urlState.category_id
      ? decodeURIComponent(urlState.category_id)
          .split(",")
          .map((f) => Number(f))
      : [];
    comment = urlState.comment ? decodeURIComponent(urlState.comment) : "";
    insertStartDate = urlState.insert_start_date ? decodeURIComponent(urlState.insert_start_date) : "";
    insertEndDate = urlState.insert_end_date ? decodeURIComponent(urlState.insert_end_date) : "";
  }

  return {
    categoryIds,
    comment,
    insertStartDate,
    insertEndDate,
  };
};

const comment = (state = initialState, action) => {
  switch (action.type) {
    case constants.APP_CONTENTS_READY: {
      return {
        ...state,
        ...getInitialSearchConditions(),
      };
    }
    case constants.CONSTRUCTION_END_FETCH_MASTERS: {
      const { categories } = action.payload;

      if (state.categoryId === 0 && categories.length > 0) {
        return {
          ...state,
          categoryId: categories[0].category_id,
        };
      }

      return state;
    }
    case constants.COMMENT_CHANGE_CATEGORY: {
      return {
        ...state,
        categoryIds: action.payload,
      };
    }
    case constants.COMMENT_CHANGE_COMMENT: {
      return {
        ...state,
        comment: action.payload,
      };
    }
    case constants.COMMENT_CHANGE_DATE_FROM: {
      return {
        ...state,
        insertStartDate: action.payload,
      };
    }
    case constants.COMMENT_CHANGE_DATE_TO: {
      return {
        ...state,
        insertEndDate: action.payload,
      };
    }
    case constants.COMMENT_CLEAR_SEARCH: {
      return {
        ...initialState,
        categoryIds: action.payload.categoryIds,
        comment: action.payload.comment,
        insertStartDate: action.payload.insertStartDate,
        insertEndDate: action.payload.insertEndDate,
      };
    }
    default:
      return state;
  }
};

export default comment;
