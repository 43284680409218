import { TimeStamp } from "@/models/timestamp";

export const MATRIX_TABLE_DATA_WIDTH = 80;

export type DragColumn = {
  id: number;
  index: number;
  offsetLeft: number;
  offsetTop: number;
  offsetWidth: number;
};

export type MatrixSearchParams = {
  categoryId: number;
  primaryChargeId: number;
  areaIds?: number[];
  deviceIds?: number[];
  facilityManagementIds?: number[];
  constructionManagementIds?: number[];
  otherIds?: number[];
  systemIds?: number[];
  companyIds?: number[];
  userIds?: number[];
  groupIds?: number[];
  processMiddleClassIds?: number[];
  itemText?: string;
  processText?: string;
  regulation?: string;
  filters?: number[];
};

// 保存する検索条件は、APIのパラメータと同じキーにする
export type MatrixFilterConfig = {
  category_id: number;
  primary_charge_id: number;
  area_id?: number[];
  device_id?: number[];
  facility_management_id?: number[];
  construction_management_id?: number[];
  other_id?: number[];
  system_id?: number[];
  company_id?: number[];
  user_id?: number[];
  group_id?: number[];
  process_middle_class_ids?: number[];
  item_text?: string;
  process_text?: string;
  regulation?: string;
  filter?: number[];
};

export type MatrixItem = {
  item_id: number;
  item_name: string;
  sort: number;
};

export type Process = {
  process_id: number;
  category_id: number;
  process_major_class_id: number;
  process_middle_class_id: number;
  coordinate_flg: boolean;
  company_id: number;
  process_name: string;
  field_p1: string;
  field_p2: string;
  field_p3: string;
  output_flg: boolean;
  schedule_roles: number[];
  weight: number;
  sort: number;
  roles: number[];
  timestamp: TimeStamp;
};

export type ProcessSegmentLabel = {
  segment_label_id: number;
  category_id: number;
  primary_charge_id: number;
  sort: number;
  segment_label_name: string;
};

export const MatrixSearchKey = {
  Category: "category_id",
  PrimaryCharge: "primary_charge_id",
  Area: "area_id",
  Device: "device_id",
  FacilityManagement: "facility_management_id",
  ConstructionManagement: "construction_management_id",
  Other: "other_id",
  Company: "company_id",
  ProcessMiddleClass: "process_middle_class_id",
  Regulation: "regulation",
  System: "system_id",
  User: "user_id", // 担当者: userとgroup
  ItemText: "item_text",
  ProcessText: "process_text",
  FilterComing: "filter_coming", // 予定工事
  FilterExpired: "filter_expired", // 作業期限超
  FilterAssigned: "filter_assigned", // 担当作業
  FilterNotCompleted: "filter_not_completed", // 未完了
} as const;

export type MatrixSearchKeyType = (typeof MatrixSearchKey)[keyof typeof MatrixSearchKey];

export const MatrixColumnKey = {
  No: "no",
  DeliveryFlg: "delivery_flg",
  ItemComplete: "item_complete",
  Totaltest: "totaltest",
  Area: "area",
  Device: "device",
  PrimaryCharge: "primary_charge",
  DocumentNo: "document_no",
  Item: "item",
  SpecialWork: "special_work", // 千葉専用
  Protection: "protective_equipment", // 千葉専用
  Regulation: "regulation",
  Systems: "systems",
  Note: "note",
  User: "user",
} as const;

export type MatrixColumnKeyType = (typeof MatrixColumnKey)[keyof typeof MatrixColumnKey];
