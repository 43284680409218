import moment from "moment";
import React, { forwardRef, useMemo } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

import Select from "../common/Select";

import { FormGrid, FormGroup, FormRow, Spacer } from "@/components/common";
import { SearchConditionConfigModal } from "@/components/common/SearchCondition/SearchConditionConfigModal";
import { SearchConditionDisplayButton } from "@/components/common/SearchCondition/SearchConditionDisplayButton";
import { SettingIconButton } from "@/components/common/SettingIconButton";
import { useSearchConditionConfig } from "@/hooks/useSearchConditionConfig";
import { ConstructionMasters } from "@/models/masters";
import { SearchConditionConfigDefinition, ScreenName } from "@/models/screenConfig";
import { SystemSearchKey, SystemSearchKeyType, SystemSearchParams } from "@/models/system";

import "react-datepicker/dist/react-datepicker.css";

type Props = {
  masters: ConstructionMasters;
  areaIds: number[];
  deviceIds: number[];
  facilityManagementIds: number[];
  systemName: string;
  constructionManagementIds: number[];
  primaryChargeIds: number[];
  otherIds: number[];
  note: string;
  scheStartDate: string;
  scheEndDate: string;
  closeStartDate: string;
  closeEndDate: string;
  categoryId: number;
  totalTest: number;
  preparation: boolean;
  error: any;
};

type DispatchProps = {
  changeArea: (areaIds: number[]) => void;
  changeDevice: (deviceIds: number[]) => void;
  changeFacilityManagement: (facilityManagementIds: number[]) => void;
  changeSystemName: (systemName: string) => void;
  changeConstructionManagement: (constructionManagementIds: number[]) => void;
  changePrimaryCharge: (primaryChargeIds: number[]) => void;
  changeOther: (otherIds: number[]) => void;
  changeNote: (note: string) => void;
  changeScheduledDateFrom: (date: Date) => void;
  changeScheduledDateTo: (date: Date) => void;
  changeCloseDateFrom: (date: Date) => void;
  changeCloseDateTo: (date: Date) => void;
  changeCategory: (categoryId: number) => void;
  changeTotalTest: (totalTest: number) => void;
  changePreparation: (preparation: boolean) => void;
  handleSearch: (option?: { params?: SystemSearchParams }) => void;
  handleClear: () => void;
};

type SearchPanelProps = Props & DispatchProps;

const SearchPanel = forwardRef<HTMLDivElement, SearchPanelProps>(
  (
    {
      masters,
      areaIds,
      deviceIds,
      facilityManagementIds,
      systemName,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      note,
      scheStartDate,
      scheEndDate,
      closeStartDate,
      closeEndDate,
      categoryId,
      totalTest,
      preparation,
      error,
      changeArea,
      changeDevice,
      changeFacilityManagement,
      changeSystemName,
      changeConstructionManagement,
      changePrimaryCharge,
      changeOther,
      changeNote,
      changeScheduledDateFrom,
      changeScheduledDateTo,
      changeCloseDateFrom,
      changeCloseDateTo,
      changeCategory,
      changeTotalTest,
      changePreparation,
      handleClear,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();

    // データ取り込み画面の検索条件設定の定義
    const systemSearchConditionDefinitions = useMemo<SearchConditionConfigDefinition<SystemSearchKeyType>[]>(
      () => [
        {
          itemsPerLine: 3,
          items: [
            { key: SystemSearchKey.Area, label: t("area"), required: false },
            { key: SystemSearchKey.Device, label: t("device"), required: false },
            { key: SystemSearchKey.FacilityManagement, label: t("facility_management"), required: false },
            { key: SystemSearchKey.SystemName, label: t("system_name"), required: false },
            { key: SystemSearchKey.ConstructionManagement, label: t("construction_management"), required: false },
            { key: SystemSearchKey.PrimaryCharge, label: t("primary_charge"), required: false },
            { key: SystemSearchKey.Other, label: t("other"), required: false },
            { key: SystemSearchKey.Note, label: t("note"), required: false },
            { key: SystemSearchKey.ScheduleDate, label: t("scheduled_date"), required: false },
            { key: SystemSearchKey.CloseDate, label: t("result_date"), required: false },
            { key: SystemSearchKey.Category, label: t("machines_category"), required: false },
            { key: SystemSearchKey.TotalTest, label: t("unadministered") + "/" + t("completed"), required: false },
            { key: SystemSearchKey.Preparation, label: t("airtight_preparation"), required: false },
          ],
        },
      ],
      [t]
    );

    // 検索条件設定
    const config = useSearchConditionConfig<SystemSearchKeyType>({
      screenName: ScreenName.SYSTEM,
      requiredKeys: systemSearchConditionDefinitions.flatMap((v) =>
        v.items.filter((item) => item.required).map((item) => item.key)
      ),
    });

    const handleSearch = (e: React.MouseEvent) => {
      e.preventDefault();

      if (config.showAllConditions) {
        props.handleSearch();
      } else {
        // 検索条件設定で表示している検索条件のみ有効とする
        props.handleSearch({
          params: {
            areaIds: config.map.get(SystemSearchKey.Area) ? areaIds : [],
            deviceIds: config.map.get(SystemSearchKey.Device) ? deviceIds : [],
            categoryId: config.map.get(SystemSearchKey.Category) ? categoryId : 0,
            facilityManagementIds: config.map.get(SystemSearchKey.FacilityManagement) ? facilityManagementIds : [],
            constructionManagementIds: config.map.get(SystemSearchKey.ConstructionManagement)
              ? constructionManagementIds
              : [],
            primaryChargeIds: config.map.get(SystemSearchKey.PrimaryCharge) ? primaryChargeIds : [],
            otherIds: config.map.get(SystemSearchKey.Other) ? otherIds : [],
            systemName: config.map.get(SystemSearchKey.SystemName) ? systemName : "",
            note: config.map.get(SystemSearchKey.Note) ? note : "",
            scheStartDate: config.map.get(SystemSearchKey.ScheduleDate) ? scheStartDate : "",
            scheEndDate: config.map.get(SystemSearchKey.ScheduleDate) ? scheEndDate : "",
            closeStartDate: config.map.get(SystemSearchKey.CloseDate) ? closeStartDate : "",
            closeEndDate: config.map.get(SystemSearchKey.CloseDate) ? closeEndDate : "",
            totalTest: config.map.get(SystemSearchKey.TotalTest) ? totalTest : -1,
            preparation: config.map.get(SystemSearchKey.Preparation) ? preparation : undefined,
          },
        });
      }
    };

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const value = e.target.value;
      if (value === "") {
        changePreparation(undefined);
      } else {
        changePreparation(value === "true");
      }
    };

    if (!config.map) return <></>;

    return (
      <div ref={ref}>
        <div className="flex justify-end bg-[#e4e4e4] w-[1200px]">
          <SettingIconButton className="w-[32px] p-[8px]" onClick={config.openSettingModal} />
        </div>
        <div className="search-box">
          <div className="grid gap-y-[16px]">
            <FormGrid
              className="grid-cols-4"
              display={config.show([
                SystemSearchKey.Area,
                SystemSearchKey.Device,
                SystemSearchKey.FacilityManagement,
                SystemSearchKey.SystemName,
                SystemSearchKey.ConstructionManagement,
                SystemSearchKey.PrimaryCharge,
                SystemSearchKey.Other,
                SystemSearchKey.Note,
              ])}
            >
              <FormGroup title={t("area")} display={config.show([SystemSearchKey.Area])}>
                <Select prefix="area" isMulti={true} options={masters.areas} value={areaIds} onChange={changeArea} />
              </FormGroup>
              <FormGroup title={t("device")} display={config.show([SystemSearchKey.Device])}>
                <Select
                  prefix="device"
                  isMulti={true}
                  options={masters.devices}
                  value={deviceIds}
                  onChange={changeDevice}
                />
              </FormGroup>
              <FormGroup title={t("facility_management")} display={config.show([SystemSearchKey.FacilityManagement])}>
                <Select
                  prefix="facility_management"
                  isMulti={true}
                  options={masters.facility_managements}
                  value={facilityManagementIds}
                  onChange={changeFacilityManagement}
                />
              </FormGroup>
              <FormGroup title={t("system_name")} display={config.show([SystemSearchKey.SystemName])}>
                <input
                  data-test-id="text-system-system-name"
                  type="text"
                  className="form-control"
                  value={systemName}
                  onChange={(e) => changeSystemName(e.target.value)}
                />
              </FormGroup>
              <FormGroup
                title={t("construction_management")}
                display={config.show([SystemSearchKey.ConstructionManagement])}
              >
                <Select
                  prefix="construction_management"
                  isMulti={true}
                  options={masters.construction_managements}
                  value={constructionManagementIds}
                  onChange={changeConstructionManagement}
                />
              </FormGroup>
              <FormGroup title={t("primary_charge")} display={config.show([SystemSearchKey.PrimaryCharge])}>
                <Select
                  prefix="primary_charge"
                  isMulti={true}
                  options={masters.primary_charges}
                  value={primaryChargeIds}
                  onChange={changePrimaryCharge}
                />
              </FormGroup>
              <FormGroup title={t("other")} display={config.show([SystemSearchKey.Other])}>
                <Select
                  prefix="other"
                  isMulti={true}
                  options={masters.others}
                  value={otherIds}
                  onChange={changeOther}
                />
              </FormGroup>
              <FormGroup title={t("note")} display={config.show([SystemSearchKey.Note])}>
                <input
                  data-test-id="text-system-note"
                  type="text"
                  className="form-control"
                  value={note}
                  onChange={(e) => changeNote(e.target.value)}
                />
              </FormGroup>
            </FormGrid>
            <FormGrid
              className="grid-cols-3"
              display={config.show([SystemSearchKey.ScheduleDate, SystemSearchKey.CloseDate])}
            >
              <FormGroup title={t("scheduled_date")} display={config.show([SystemSearchKey.ScheduleDate])}>
                <div className="d-ib">
                  <DatePicker
                    wrapperClassName="w-120"
                    disabled={false}
                    selected={scheStartDate ? moment(scheStartDate).toDate() : null}
                    dateFormat="yyyy/MM/dd"
                    locale={t("calender_locale")}
                    onChange={(date) => changeScheduledDateFrom(date)}
                  />
                </div>
                <div className="d-ib w-30 ta-c">&nbsp;〜&nbsp;</div>
                <div className="d-ib">
                  <DatePicker
                    wrapperClassName="w-120"
                    disabled={false}
                    selected={scheEndDate ? moment(scheEndDate).toDate() : null}
                    dateFormat="yyyy/MM/dd"
                    locale={t("calender_locale")}
                    onChange={(date) => changeScheduledDateTo(date)}
                  />
                </div>
                {error.scheDate && (
                  <div className="form-error w-380">
                    <p className="form-error-message w-300">{error.scheDate}</p>
                  </div>
                )}
              </FormGroup>
              <FormGroup title={t("result_date")} display={config.show([SystemSearchKey.CloseDate])}>
                <div className="d-ib">
                  <DatePicker
                    wrapperClassName="w-120"
                    disabled={false}
                    selected={closeStartDate ? moment(closeStartDate).toDate() : null}
                    dateFormat="yyyy/MM/dd"
                    locale={t("calender_locale")}
                    onChange={(date) => changeCloseDateFrom(date)}
                  />
                </div>
                <div className="d-ib w-30 ta-c">&nbsp;〜&nbsp;</div>
                <div className="d-ib">
                  <DatePicker
                    wrapperClassName="w-120"
                    disabled={false}
                    selected={closeEndDate ? moment(closeEndDate).toDate() : null}
                    dateFormat="yyyy/MM/dd"
                    locale={t("calender_locale")}
                    onChange={(date) => changeCloseDateTo(date)}
                  />
                </div>
                {error.closeDate && (
                  <div className="form-error w-380">
                    <p className="form-error-message w-260">{error.closeDate}</p>
                  </div>
                )}
              </FormGroup>
            </FormGrid>
            <FormGrid
              className="grid-cols-4"
              display={config.show([SystemSearchKey.Category, SystemSearchKey.TotalTest, SystemSearchKey.Preparation])}
            >
              <FormGroup title={t("machines_category")} display={config.show([SystemSearchKey.Category])}>
                <Select
                  prefix="category"
                  isMulti={false}
                  options={masters.categories}
                  value={categoryId}
                  onChange={changeCategory}
                  isClearable={true}
                />
              </FormGroup>
              <FormGroup
                title={t("unadministered") + "/" + t("completed")}
                display={config.show([SystemSearchKey.TotalTest])}
              >
                <select
                  data-test-id="select-system-completed"
                  className="form-control"
                  value={totalTest}
                  onChange={(e) => changeTotalTest(Number(e.target.value))}
                >
                  <option key={-1} value={-1}>
                    -----
                  </option>
                  <option key={0} value={0}>
                    {t("unadministered")}
                  </option>
                  <option key={1} value={1}>
                    {t("completed")}
                  </option>
                </select>
              </FormGroup>
              <FormGroup title={t("airtight_preparation")} display={config.show([SystemSearchKey.Preparation])}>
                <select
                  data-test-id="select-system-airtight-preparation"
                  className="form-control"
                  value={preparation !== undefined ? preparation.toString() : ""}
                  onChange={handleChange}
                >
                  <option key={-1} value="">
                    -----
                  </option>
                  <option key={0} value="false">
                    {t("not_completed")}
                  </option>
                  <option key={1} value="true">
                    {t("completed")}
                  </option>
                </select>
              </FormGroup>
            </FormGrid>
            <FormRow className="justify-between mt-[16px]">
              <Spacer x="210px" />
              <SearchConditionDisplayButton
                isExpanded={config.showAllConditions}
                onChange={config.toggleDisplayOptionalConditions}
              />
              <div className="flex gap-x-4">
                <button className="btn btn-gray" onClick={handleClear}>
                  {t("reset")}
                </button>
                <button className="btn btn-blue" onClick={handleSearch}>
                  {t("search")}
                </button>
              </div>
            </FormRow>
          </div>
        </div>
        {config.showSettingModal && (
          <SearchConditionConfigModal<SystemSearchKeyType>
            title={t("search_condition_config")}
            configMap={config.map}
            definitions={systemSearchConditionDefinitions}
            onClose={config.closeSettingModal}
            onSubmit={(c) => config.saveSearchItemConfig(c)}
          />
        )}
      </div>
    );
  }
);

SearchPanel.displayName = "SearchPanel";

export default SearchPanel;
